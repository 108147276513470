@import '../../sass/variables';

.app-footer {
    position: relative;

    .footer-box {
        width: 100%;
        margin: 0 auto;

        .footer-box-container {
            @media only screen and (max-width: $desktop-md-breakpoint) {
                padding: 0 3rem;
            }
            @media only screen and (max-width: $mobile-breakpoint) {
                padding: 0 1rem;
            }
        }

        .footer-container {
            max-width: 1024px;
            margin: 0 auto;
            background: url(./../../images/footer-bg.svg) no-repeat center center;
            background-size: contain;
            justify-content: space-between;
            display: flex;
            font-size: 0.875rem;

            a {
                color: $color-black;
                text-decoration: none;
                white-space: nowrap;
            }

            .col {
                h4 {
                    margin-bottom: .5rem;
                    text-transform: uppercase;
                }

                ul {
                    li {
                        display: block;
                        margin-bottom: 1rem;
                    }
                }
            }

            .widget-subscription {
                max-width: 26.875rem;

                .subscription-box {
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media screen and (max-width: $desktop-md-breakpoint) {
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                    }
                }

                p {
                    margin-bottom: 1.5rem;
                }

                @media only screen and (max-width: 1024px) {
                    margin: 0 auto;
                    max-width: 32.875rem;
                }

            }

            .widget-social-links {
                font-size: get-font('x-large');

                ul {
                    display: flex;
                    margin-top: 0.75rem;

                    li {
                        margin-right: 1rem;

                        i {
                            color: $color-grey-base;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .small {
                margin: 0 auto;
                max-width: 1400px;
                background-size: contain;
                background: url(./../../images/footer-bg.svg) no-repeat center center;
                justify-content: space-between;
                display: flex;
                font-size: 0.875rem;

                .app-footer-logo {
                    width: 3.6rem;
                    height: 0.94rem;
                    background-image: url("../../images/app-footer-logo.svg");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    align-self: flex-end;
                    margin-bottom: 1rem;
                }

                a {
                    color: $color-grey-base;
                    text-decoration: none;
                }

                .widget-subscribtion {
                    max-width: 26.875rem;

                }

                .widget-social-links {
                    font-size: get-font('x-large');
                    align-self: flex-end;
                    margin-bottom: 1rem;

                    ul {
                        display: flex;

                        li {
                            margin-right: 1rem;

                            i {
                                color: $color-grey-base;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .widget-menu {
                    align-self: flex-end;
                    color: #B5B5B5;

                    .row {
                        margin: 0;

                        ul {
                            margin-right: 1rem;
                            display: flex;
                        }
                    }

                    .copyright {
                        text-align: center;
                        padding: 0;
                    }
                }

                // desktop only
                @media only screen and (min-width: $tablet-portrait-breakpoint) {
                    min-height: 25.75rem;
                }
                @media only screen and (max-width: $mobile-breakpoint) {
                    min-height: 18.5rem;
                    flex-direction: column;
                    justify-content: center;
                    background-size: cover;
                    .row {
                        margin: 0;

                        ul {
                            display: flex;

                            li {
                                &::after {
                                    padding: 0 0.3125rem;
                                    content: '|'
                                }

                                &:last-child {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Desktop only
            @media only screen and (min-width: $tablet-portrait-breakpoint) {
                padding-top: 5.625rem;
            }
            @media only screen and (max-width: 1024px) {
                @include app-footer($footer-list-color);

                .widget-subscription {
                    text-align: center;
                }
            }
            @media only screen and (max-width: 600px) {
                padding: 0;
            }
        }

        &.layout-home,
        &.layout-secondary {

            .widget-menu {
                .row {
                    margin: 0;

                    ul {
                        margin-right: 1rem;
                        @media only screen and (max-width: 1024px) {
                            margin: 0 auto;
                        }
                    }
                }

                @media only screen and (max-width: 1024px) {
                    h4 {
                        display: none;
                    }
                }

                @media only screen and (max-width: 550px) {
                    ul {
                        li {
                           a {
                                font-size: get-font('x-small');
                           }
                        }
                    }
                }
            }
        }

        .footer-bottom-container {
            z-index: 1;
            position: relative;
            background-color: #e4e4e5;
            padding: 0 1rem;

            &::before {
                @media only screen and (max-width: $mobile-small) {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1rem;
                    bottom: 0;
                    background-color: #b5b5b5e8;
                    left: 0;
                }
            }
        }

        .footer-bottom {
            max-width: 1024px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 0 auto;

            .social-links {
                display: flex;
                cursor: pointer;
                z-index: 1;

                li {
                    i {
                        color: $color-grey-base;
                        font-size: get-font('medium');
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            & > a {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;

            }

            .logo {
                width: 3.625rem;
            }

            .policy {
                margin-bottom: 0.225rem;
                align-self: flex-end;
                color: $color-grey-base;
                text-align: right;
                font-size: 0.625rem;

                a {
                    color: $color-grey-base;
                }

                a:first-child {
                    border-right: 1px solid $color-grey-base;
                    padding-right: 5px;
                    margin-right: 5px;
                }
            }

            .copyright {
                font-size: 0.625rem;
                padding: 0;
                color: $color-grey-base;
            }

            .center-copy {
                flex: 1;
                text-align: center;

                a {
                    display: block;
                    img {
                        width: 4.5rem !important;
                    }
                }

                p {
                    color: get-color('grey', 'dark');
                }

                @media (max-width: 600px) {
                    margin-left: 0 !important;
                }

                @media only screen and (max-width: $mobile-small) {
                    .copyright {
                        p {
                            display: none;
                        }
                    }
                }
            }

            .mobile-copyright {
                display: none;

                .copyright {
                    a {
                        display: none
                    }
                }

                @media only screen and (max-width: $mobile-small) {
                    display: block;
                    order: 3;
                    z-index: 1;

                    .copyright {
                        color: $color-white;
                    }
                }
            }

            .terms-and-cond {
                flex: 1;

                .terms-and-conditions {
                    text-align: right;

                    li {
                        margin-right: 0;
                        white-space: nowrap !important;

                        a {
                            display: block;
                            color: get-color('grey', 'dark');
                            font-size: 0.675rem;
                        }
                    }

                    li:nth-child(1) {
                        position: relative;

                        @media screen and (max-width: 600px) {
                            margin-right: 0.675rem;

                            &::after {
                                position: absolute;
                                content: "";
                                height: 0.675rem;
                                width: 1px;
                                bottom: 4px;
                                background-color: get-color('grey', 'dark');
                                right: -7px;
                            }
                        }
                    }

                    li:nth-child(2) {
                        position: relative;

                        @media screen and (max-width: 600px) {
                            margin-right: 0.675rem;

                            &::after {
                                position: absolute;
                                content: "";
                                height: 0.675rem;
                                width: 1px;
                                bottom: 4px;
                                background-color: get-color('grey', 'dark');
                                right: -7px;
                            }
                        }
                    }

                    li:nth-child(3) {
                        position: relative;

                        @media screen and (max-width: 600px) {
                            margin-right: 0.675rem;

                            &::after {
                                position: absolute;
                                content: "";
                                height: 0.675rem;
                                width: 1px;
                                bottom: 4px;
                                background-color: get-color('grey', 'dark');
                                right: -7px;
                            }
                        }
                    }

                }
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;
                ul {
                    order: 1;
                }
                .terms-and-conditions {
                    display: flex !important;
                    margin: 0 auto;
                    text-align: center !important;
                }
            }
        }
    }
}
