.hr-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CAD7E3;
    border-bottom: 1px solid #CAD7E3;
    text-align: center;
    font-size: 0.875rem;
    position: relative;
    margin: 1.75rem 0;

    span{
        position: absolute;
        background: #FFFFFF;
        padding: 0.3rem 1rem;
    }
}
