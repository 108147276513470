@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icons/fonts/icomoon.eot?uxoh1g');
    src:  url('../fonts/icons/fonts/icomoon.eot?uxoh1g#iefix') format('embedded-opentype'),
    url('../fonts/icons/fonts/icomoon.ttf?uxoh1g') format('truetype'),
    url('../fonts/icons/fonts/icomoon.woff?uxoh1g') format('woff'),
    url('../fonts/icons/fonts/icomoon.svg?uxoh1g#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-settings:before {
  content: "\e903";
}
.icon-draw-map:before {
  content: "\e904";
}
.icon-bedrooms:before {
  content: "\e905";
}
.icon-square-m:before {
  content: "\e906";
}
.icon-floors:before {
  content: "\e907";
}
.icon-logout:before {
  content: "\e908";
}
.icon-share:before {
  content: "\e909";
}
.icon-more:before {
  content: "\e910";
}
.icon-images:before {
  content: "\e911";
}
.icon-view:before {
  content: "\e912";
}
.icon-location:before {
  content: "\e913";
}
.icon-check-mark:before {
  content: "\e914";
}
.icon-ic-logo-mini:before {
    content: "\e998";
}
.icon-launch:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e916";
}
.icon-rooms:before {
  content: "\e917";
}
.icon-avatar:before {
  content: "\e918";
}
.icon-premum-member:before {
  content: "\e919";
}
.icon-vip-member:before {
  content: "\e920";
}
.icon-ic-viber:before {
  content: "\e921";
}
.icon-ic-whatsapp:before {
  content: "\e922";
}
.icon-ic-telegram:before {
  content: "\e923";
}
.icon-add-image:before {
  content: "\e924";
}
.icon-calendar:before {
  content: "\e925";
}
.icon-mortgage:before {
  content: "\e926";
}
.icon-about-building:before {
  content: "\e927";
}
.icon-about-location:before {
  content: "\e928";
}
.icon-about-apartments:before {
  content: "\e929";
}
.icon-ic-floor-plan:before {
  content: "\e930";
}
.icon-contacts:before {
  content: "\e931";
}
.icon-area:before {
  content: "\e932";
}
.icon-floor-sq:before {
  content: "\e933";
}
.icon-payment:before {
  content: "\e934";
}
.icon-payment-sq:before {
  content: "\e935";
}
.icon-my-annoucements:before {
  content: "\e936";
}
.icon-favorites:before {
  content: "\e937";
}
.icon-help:before {
  content: "\e938";
}
.icon-email:before {
  content: "\e939";
}
.icon-password:before {
  content: "\e940";
}
.icon-phone:before {
  content: "\e941";
}
.icon-social-f:before {
  content: "\e942";
}
.icon-social-y:before {
  content: "\e943";
}
.icon-filter:before {
  content: "\e944";
}
.icon-filter-fill:before {
  content: "\e945";
}
.icon-dropdown-arrow:before {
  content: "\e946";
}
.icon-chevron-down:before {
  content: "\e947";
}
.icon-ic-map:before {
  content: "\e948";
}
.icon-ic-sorting:before {
  content: "\e949";
}
.icon-ic-star-full:before {
  content: "\e950";
}
.icon-add-announcement:before {
  content: "\e951";
}
.icon-ic-make-premium:before {
  content: "\e952";
}
.icon-ic-premium:before {
  content: "\e953";
}
.icon-ic-no-search:before {
  content: "\e954";
}
.icon-ic-remove:before {
  content: "\e955";
}
.icon-ic-edit:before {
  content: "\e956";
}
.icon-ic-menu:before {
  content: "\e957";
}
.icon-ic-history:before {
  content: "\e958";
}
.icon-ic-fb-circle:before {
  content: "\e959";
}
.icon-ic-gm-circle:before {
  content: "\e960";
}
.icon-ic-required:before {
  content: "\e961";
}
.icon-ic-loader:before {
  content: "\e962";
}
.icon-ic-username:before {
  content: "\e963";
}
.icon-ic-warning:before {
  content: "\e964";
}
.icon-ic-rub:before {
  content: "\e965";
}
.icon-ic-usd:before {
  content: "\e966";
}
.icon-ic-amd:before {
  content: "\e967";
}
.icon-ic-error:before {
  content: "\e968";
}
.icon-ic-rest-room:before {
  content: "\e969";
}
.icon-ic-cooler:before {
  content: "\e970";
}
.icon-ic-entrance-door:before {
  content: "\e971";
}
.icon-ic-balcony:before {
  content: "\e972";
}
.icon-ic-renovation:before {
  content: "\e973";
}
.icon-ic-furniture:before {
  content: "\e974";
}
.icon-ic-windows:before {
  content: "\e975";
}
.icon-ic-floor:before {
  content: "\e976";
}
.icon-ic-appliance:before {
  content: "\e977";
}
.icon-ic-heating:before {
  content: "\e978";
}
.icon-ic-floor-plan1:before {
  content: "\e979";
}
.icon-ic-building-type:before {
  content: "\e980";
}
.icon-ic-lift:before {
  content: "\e981";
}
.icon-ic-water:before {
  content: "\e982";
}
.icon-ic-garage:before {
  content: "\e983";
}
.icon-ic-storage:before {
  content: "\e984";
}
.icon-ic-playgarden:before {
  content: "\e985";
}
.icon-ic-eco:before {
  content: "\e986";
}
.icon-ic-no-image:before {
  content: "\e987";
}
.icon-ic-statistics:before {
  content: "\e988";
}
.icon-ic-gas:before {
  content: "\e990";
}
.icon-ic-security:before {
  content: "\e992";
}
.icon-ic-price-plan:before {
  content: "\e994";
}
.icon-ic-no-image:before {
    content: "\e987";
}
.icon-ic-statistics:before {
    content: "\e988";
}
.icon-ic-pool:before {
    content: "\e999";
}
.icon-ic-gas:before {
    content: "\e1000";
}
.icon-ic-green-area:before {
    content: "\e1001";
}
.icon-ic-security:before {
    content: "\e1002";
}
.icon-ic-money:before {
    content: "\e1003";
}
.icon-ic-price-plan:before {
    content: "\e1004";
}
.icon-ic-hide-pass:before {
    content: "\e1005";
}
.icon-ic-no-favorites:before {
    content: "\e1006";
}
.icon-ic-logo-min:before {
    content: "\e1007";
}
.icon-ic-all-apts:before {
    content: "\e1008";
}
.icon-ic-free-apts:before {
    content: "\e1009";
}
.icon-ic-saved-search:before {
    content: "\e1010";
}
.icon-ic-no-announcements:before {
    content: "\e1011";
}
.icon-ic-wifi:before {
  content: "\e1012";
}
.icon-ic-bulding-floor:before {
  content: "\e1013";
}
.icon-ic-bulding-name:before {
  content: "\e1014";
}
.icon-ic-constructor-name:before {
  content: "\e1015";
}
.icon-new_building_type:before {
    content: "\e1016";
}
.icon-ic-phone-circle:before {
    content: "\e1017";
}
.icon-ic_website:before {
  content: "\e1018";
}
.icon-ic-web:before {
    content: "\e1018";
}
.icon-ic-location-circle:before {
    content: "\e1019";
}
.icon-ic-web-circle:before {
    content: "\e1020";
}
.icon-ic-email-circle:before {
    content: "\e1021";
}
.icon-ic-map2:before {
    content: "\e1022";
}
.icon-arrow_up:before {
  content: "\e1023";
}
