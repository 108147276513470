// Skeleton base
@keyframes wave-skeleton {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}
.cm-skeleton{
    display: block;
    height: 1em;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.11);
    position: relative;
    overflow: hidden;
    &:after{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        animation: wave-skeleton 1.6s linear 0.5s infinite;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }

}

.cm-skeleton-btn{

    display: block;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.11);
    position: relative;
    overflow: hidden;
    &:after{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        animation: wave-skeleton 1.6s linear 0.5s infinite;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }
}
