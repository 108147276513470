
/****  Override classes ****/
//TODO move to mixin
menu {
    ::-webkit-scrollbar {
        width: 0.5rem !important;
        height: 0 !important;
    }
    ::-webkit-scrollbar-button {
        width: 0 !important;
        height: 0 !important;
        background: transparent !important;
    }
    ::-webkit-scrollbar-button:hover {
        background: transparent !important;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4) !important;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(161, 161, 161, 0.45) !important;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent !important;
    }
}

.filter-item-block {

& .multiple {
    &.selection {
        padding: unset;
    }

    &.dropdown {
        border-color: unset;
        box-shadow: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        line-height: unset;
        min-width: unset;
        //min-height: unset;
        background: unset;
        border: unset;
        border-radius: unset;
        height: 100%;
        padding: 0 0.6875rem;
        //bottom: unset;

        & .menu {
            border-color: unset;
            box-shadow: unset;
            max-height: unset;
            border-top-width: 1px !important;

            & .item {
                border-top: unset;
                padding: unset;
            }
        }
    }
}
}

/*****    END OVERRIDING    ******/

.search.icon {
    background-color: transparent;
    font-size: 1.25rem;
}

.filter-group {
    background-color: $color-light-grey-bg;
    padding: 1rem 0 1rem 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: $filters-height;
    @include fluid-type(920px, 1600px, 10px, 16px);
    & .priority-items-row {
        position: relative;
        width: 100%;
        justify-content: space-between;

        & .priority-item {
            & .pointing.dropdown:after {
                left: 10% !important;
            }

            .item-open-bordered {
                & .menu {
                    margin: 14px 0 0 0 !important;
                    padding: 1.5rem 0 0 0 !important;
                    min-width: 270px !important;
                    max-height: 515px;
                    overflow: auto;
                    right: 0;
                    box-shadow: 0px 3px 6px #00000029;
                    justify-content: space-between;
                    border: 1px solid $color-grey-light;

                    .search-in-districts {
                        position: sticky;
                        top: 0;
                        background: white;
                        z-index: 100;
                        margin: 0 .5rem;
                        padding: 1rem 0;

                        input {
                            padding: 1rem 1rem;
                        }
                    }

                    & .item {
                        input {
                            margin-right: 1.125rem !important;
                            cursor: pointer;
                        }

                        &.ui.checkbox {
                            label {
                                text-transform: capitalize;
                            }
                        }
                    }

                    & .menu-footer {
                        background: $color-light-grey-bg;
                        border-top: 1px solid get-color('grey', 'base') !important;
                        padding: 0.625rem;
                        margin-top: 2.3rem;
                        position: sticky;
                        bottom: 0;
                        z-index: 100;
                    }

                    &:after {
                        left: 10% !important;
                    }
                }
            }
        }
    }

    & .more-items {
        position: static !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.85rem;
        flex: .5;

        &.dropdown {
            &:after {
                visibility: hidden;
            }

            & .more-items-group-row {
                border-top: 1px solid $color-main-blue;
                background-color: $color-light-grey-bg !important;
                min-width: 100%;

                &:after {
                    top: -4.5px !important;
                }

                & .more-item-list {
                    padding: 1.625rem 1.875rem;
                    justify-content: start;
                    align-items: flex-start;

                    .required {
                        label:after {
                            content: "**";
                            top: 0;
                        }
                    }
                }
            }
        }

        .more-item {
            margin-right: 1.25rem;
            margin-bottom: 1.65rem;
            min-width: 250px;
            flex-grow: 0;

            input[type=text] {
                height: 38px;
            }

            .menu {
                bottom: unset;
                left: unset !important;
                right: 0;
                top: 100% !important;
                border: 1px solid get-color('grey', 'light') !important;

                .item {
                    border-top: none !important;
                }
            }

            .icon-up-top i {
                color: $color-main-green;

                &:after {
                    content: '\f0d8';
                    position: absolute;
                    top: 35%;
                }

                &:before {
                    content: '\f0d7';
                    position: absolute;
                    top: 85%;
                }
            }

            & .section-block-checkbox {
                width: 0;
            }
        }
    }

    & .remember-btn {
        margin-left: 0.325rem;
        height: 3rem;
        align-self: center;
    }

    & .filter-item-block {
        &:first-child {
            padding-left: .125rem;
        }

        flex: 1;
        justify-content: space-between;

        &.hidden-filter {
            visibility: hidden;
            opacity: 0;
            position: absolute;
        }

        & .filter-datetime {
            margin: 0;
            width: 100%;
            flex: 1 0 auto;
            outline: 0;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            text-align: left;
            line-height: 1.21428571em;
            font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            padding: .67857143em 1em;
            background: #fff;
            border: 1px solid rgba(34, 36, 38, .15);
            color: rgba(0, 0, 0, .87);
            border-radius: .28571429rem;
            transition: box-shadow .1s ease, border-color .1s ease;
            box-shadow: none;
        }
    }

    & .label {
        background-color: transparent !important;
    }

    & .filter-block-text {
        color: get-color('grey', 'base');
        padding-bottom: 0.625rem;
    }

    & .bordered {
        border-right: 1px solid get-color('grey', 'light') !important;
    }

    .item-open-bordered {
        min-height: 4.8125rem;
        min-width: 10rem;
        padding: 0 0.75rem;

        &.active.visible {
            background: #fff;
            box-shadow: 0px -3px $color-main-green;
        }

        &.filter-dropdown .divider.text {
            color: get-color('grey', 'base');
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            padding-right: 0.5rem;
            white-space: nowrap;
        }

        .selected-filter {
            width: 100%;
            font-weight: bold;
            color: #000;
            display: flex;
        }

        & .selected-filter-count {
            margin-left: 0.825rem;
        }
    }

    .filter-dropdown {
        justify-content: space-between;
        width: 100%;
    }

    .filter-dropdown-icons {
        color: $color-main-green;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

// deal type circles (rent - blue, sell - )
.deal-filter {
    &:before {
        content: "";
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin: 0 0.358rem 0.1rem 0;
    }

    &.deal-sell:before {
        background-color: #FD5D7C;
    }

    &.deal-rent:before {
        background-color: $color-main-blue;
    }

    &.deal-daily_rent:before {
        background-color: $color-main-green;
    }
}
.main-green {
    color: $color-main-green !important;
}
.remember-toast {
    text-align: center;

    &.css-1mf073k-ToastElement{
        background-color: white;
        .css-3mfg68-Icon {
            padding: 12px 5px;
            background-color: $color-main-green;
            color: white;
        }
        .css-18gu508-Content, .css-1klfh0r-Button {
            padding: 12px;
        }
    }
}
