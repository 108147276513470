@import '../../sass/variables';

.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.3);
}

.login-modal {
    .ui.modal {
        max-width: 26.625rem;
        padding: 0;
        text-align: center;

        .title {
            color: #000000;
            font-size: 1rem;
            margin-bottom: 2.625rem;
        }

        .content {
            padding: 3rem;


            .close-login-modal {
                position: absolute;
                display: inline-block;
                font-size: 1.5rem;
                color: get-color('primary', 'second');
                right: 0.8rem;
                top: 0;
                background: #FFFFFF;
                padding: 0.5rem;

                @media only screen and (max-width: $mobile-breakpoint) {
                  display: none;
                }

                i {
                    font-size: 1rem;
                }
            }

            h2 {
                color: get-color("primary", "first");
                font-size: 1.5rem;
                padding-bottom: 0.25rem;
                margin-top: 1.3125rem;
                line-height: 1.8125rem;
            }

            p {
                color: $color-black;
                font-size: 1rem;
                margin-bottom: 2.625rem;
                line-height: 0.9375rem;
                margin-top: 0.21875rem;
            }

            .login-form {
                max-width: 22.9375rem;
                margin: 0 auto;
            }

            .ui.checkbox {
                display: flex;

                label:before {
                    border-color: get-color("primary", "first");

                }
            }

            .form-checkbox {
                margin-bottom: unset;
            }

            .hr-divider {
                margin: 0 0 1.78125rem 0;
            }

            .response-error-message {
                margin-bottom: 1.875rem;
                white-space: nowrap;

                span {
                    text-align: center;
                    margin-top: 0;
                }
            }
        }

        p {
            color: $color-black;
            font-size: 1rem;
            margin-bottom: 1.125rem;
        }

        .login-form {
            .form-row {
                height: 3rem;

                input {
                    margin: 0;
                    height: 3rem;
                }
            }

            .btn {
                max-width: 268px;
                margin: 0;
                height: 2.875rem;
                width: 72%;
            }

            .password {
                margin-bottom: 0.875rem;
            }

            .forget-pass {
                display: block;
                margin-top: 1.6875rem;
                margin-bottom: 1.1rem;
                color: $color-primary;
            }
        }

        .social-register {
            display: flex;
            justify-content: center;
            font-size: 2.25rem;
            align-items: center;

            a {
                margin: 0 1.5625rem;
            }
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            width: 100%;
            margin: 0;
            height: 100vh;
            border: none;
            box-shadow: none;

            .scrolling.dimmable > .dimmer {
                justify-content: space-evenly;
            }

            .social-register {
                height: 2.25rem;
                font-size: 2.25rem;

                a {
                    margin: 0 1.5625rem;
                }
            }
        }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        .scrolling.dimmable > .dimmer {
            justify-content: center;
        }

        .ui.page.modals.dimmer {
            padding: 0;
            display: flex !important;
            margin-top: 0 !important;
            top: $header-mobile-height !important;
            overflow: initial;
            border-radius: 0;
            z-index: 999;
        }

        .wrap.ui.scrolling.modal.transition {
            display: flex !important;
            margin-top: 0 !important;
            position: absolute;
            overflow: initial;
            border-radius: 0;
            animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

            .content {
                position: relative;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                padding: 0;
                padding-top: 1.5625rem;
                margin-top: 0;
                overflow-y: scroll;

                .form-row {
                    input {
                        margin: 0 auto;
                    }
                }

                p {
                    color: $color-black;
                    font-size: 0.75rem;
                    margin-bottom: 2.625rem;
                    line-height: 0.9375rem;
                    margin-top: 0.21875rem;
                }

                .login-form {
                    max-width: 335px;
                    margin: 0 auto;

                    .alternative {
                        font-size: 0.875rem;
                        margin: 1rem 0;
                        color: #CAD7E3;
                    }
                }
            }
        }

        .wrap.ui.modal {
            max-width: 100%;
        }


        .wrap.ui.modal > .content {
            padding: 0;

            h2 {
                margin-top: 0;
            }
        }
    }
}
