.block {
    .container > div {

        display: flex;
    }
}
.profile {
    max-width: 22.3%;
    width: 100%;
    margin-top: 5rem;
    @media only screen and (max-width: $mobile-breakpoint) {
        display: none;
    }
    .account-nav {
        max-width: 18.5rem;
        font-weight: 600;

        .account-nav__item {
            position: relative;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-grey-light;
            border-left: 1px solid $color-grey-light;
            border-right: 1px solid $color-grey-light;

            &:first-child {
                border-top: 1px solid $color-grey-light;
            }

            a {
                display: flex;
                align-items: center;
                font-size: 1rem;
                line-height: 1.4375rem;
                color: $color-black;
                padding: 0.8125rem 2.5rem 1rem 1.875rem;
                width: 100%;
                height: 100%;

                i {
                    font-size: 1.6875rem;
                    margin-right: 1.375rem;
                    color: $color-grey-light;
                }
            }

            .icon-chevron-right {
                display: none;

            }
        }

        ul {
            background: $color-white;

            li:last-of-type {
                border-radius: 0 0 0.25rem 0.25rem;
            }

            li:first-of-type {
                border-radius: 0.25rem 0.25rem 0 0 ;
            }

            li.account-nav__item--active {
                background: $color-light-grey-bg;
                position: relative;

                a {
                    color: $color-green;
                }

                .cm-icon {
                    color: $color-green;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -12px;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-left: 12px solid $color-light-grey-bg;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -13px;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-left: 12px solid $color-grey-light;
                }
            }
        }

    }

}

.profile-content.no-result {
    max-width: 77.7%;
}

.profile-content {
    width: 100%;

    h1 {
        margin-top: 1.4375rem;
        margin-bottom: 1.0625rem;
        font-size: 2.0625rem;
        line-height: 2.5rem;
    }

    .no-result {
        padding-top: 3.125rem;
        padding-right: 12.5rem;
        @media only screen and (max-width: $mobile-breakpoint) {
            padding-right: 0;
        }


        & > div {

            display: flex;
            align-items: center;
            flex-direction: column;

            i {
                font-size: 4.875rem;
                color: #D9D9DA;
            }

            span, p {
                font-size: 1rem;
                line-height: 1.1875rem;
                text-align: center;
            }

            span {
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                color: #000000;
                font-weight: 600;

            }
        }
    }

    .settings {
        .deactivate {
            padding-top: 1.25rem;
            margin-top: 2.5rem;
            border-top: 1px solid #000000;

            .section-title {
                border-bottom: none;
            }

            .btn {
                margin-top: 1.25rem;
            }
        }
    }

    .card-box {
        width: 100%;
        display: flex;
        flex-direction: row;

        ul {
            color: $color-black;
        }
    }
}

.essential-tips {
    height: initial;
    > div {
        height: 100%;
    }
}

.account-saved {

    .green {
        color: $color-green;
    }
    .w-70 {
        width: 70%;
    }
    .w-50 {
        width: 50%;
        @media screen and (max-width: $mobile-breakpoint) {
            width: 100%;
        }
     }
    .w-30 {
        max-width: 30%;
        width: 100%;
    }
    .title {
        font-size: 0.875rem;
        text-align: left;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }
    .seaved-search-result {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-self: center;
        max-width: 53.75rem;
        font-size: 0.75rem;

        @media screen and (max-width: $mobile-breakpoint) {
           color: transparent;
        }

    }
    .container-860 {
        margin-top: 1.25rem;
        cursor: pointer;
    }
    .subscribed-btn {
        padding: 0.375rem 0.8125rem;
        background-color: $color-orange;
        color: #FFFFFF;
        font-size: 0.75rem;
        border-radius: 0.3125rem;
    }
    .remove-icon {
        i {
            color: $color-green;
            font-size: 1rem;
            padding-left: 1rem;
        }
    }
    .filters {
        font-size: 1rem;
        font-weight: bold;
        span {
            color: $color-green;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 1.25rem;
        }
    }
}
.account-page {
    background: $color-light-grey-bg;
    .layout-secondary {
        .footer-container {
            div {
                align-self: flex-end;

                @media only screen and (max-width: $tablet-portrait-breakpoint) {
                    align-self: initial;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet-portrait-breakpoint) {
    .profile-content {
        h1 {
            font-size: 1.3125rem;
        }
    }
}

/****Move to variable scss*****/

.relative {
    position: relative;
}

@keyframes name {
    from {
        opacity: 0;
        height: 0
    }
    to {
        opacity: 1;
        height: 2.375rem
    }
}
