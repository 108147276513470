#pagination {
    margin: 2rem 2rem 0 2rem;
    display: flex;
    justify-content: center;

    a {
        background: #FFFFFF;
        margin: 0 0.3rem;
        color: #000000;
        border: 0.1rem solid #B5B5B5;
        justify-content: center;
        font-size: 1rem;
        height: 1.84rem;
    }

    .active{
        background: get-color("primary", "first");
        color: #ffffff;
        border: none;
    }

    .item[type='prevItem'] {
        border: none;

        .disabled {
            visibility: hidden;
        }
    }

    .item[type='nextItem'] {
        border: none;

        .disabled {
            visibility: hidden;
        }
    }
}


//.pagination {
//    display: flex;
//    justify-content: center;
//
//    .item {
//        padding: 0.87rem;
//        margin: 0 0.45rem;
//        height: 1.87rem;
//        border: 1px solid #ababab;
//        border-radius: 0.25rem;
//    }
//
//    .item:hover {
//        background-color: get-color("primary", "first");
//        color: #FFFFFF;
//    }
//
//    //.item:first-child {
//    //    border: none;
//    //}
//    //
//    //.item:last-child {
//    //    border: none;
//    //}
//
//    a {
//        color: #1a202c;
//        width: 1.87rem;
//        height: 1.87rem;
//        border: #ababab;
//    }
//}
