.map-container {
    .place-autocomplete-box {
        width: 64%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 1.25rem;

        .inner {
            background: transparent;
            position: relative;
            overflow: hidden;

            input {
                background: transparent;
            }

            &:before {
                content: "";
                display: block;
                background: rgba($color: $color-white, $alpha: 0.9);
                z-index: -1;
                @include layer();
            }
        }

        .custom-box {
            background: none;

            &::before {
                content: "";
                display: block;
                z-index: -1;
                background: rgba($color: $color-white, $alpha: 0.8);
                @include layer();
            }
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }
}

.place-autocomplete-box {
    position: relative;
    z-index: 3;

    .inner {
        height: 3.875rem;
        border-radius: 10px;
        box-shadow: 0 0 6px #00000029;
        background: $color-white;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        input {
            padding: 0 3rem 0 3rem;
            border: none;
            outline: none;
            height: 1.6875rem;
            width: inherit;
            color: $color-black;
            font-size: 1.375rem;
            text-overflow: ellipsis !important;

            @media only screen and (max-width: $tablet-portrait-breakpoint) {
                font-size: get-font('medium');
            }

            &:focus {
                border: none;
                outline: none;
            }

            &::placeholder {
                color: $color-grey-base;
            }
        }

        & > span.search-icon-cont,
        & > span.clear-search {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            color: $color-grey-light;

            .icon-location {
                display: none;
            }
        }

        & > .search-location-icon {
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            left: 0.875rem;
            display: flex;
        }

        & > span.search-icon-cont {
            font-size: get-font('x-large');
            left: 0.875rem;
        }

        & > span.clear-search {
            right: 1.25rem;
            cursor: pointer;
        }

        & > div {
            width: 100%;
        }
    }

    .custom-box {
        position: absolute;
        width: 100%;
        top: 3rem;
        box-shadow: 0 3px 6px #00000029;
        padding: 1.25rem 0 1.125rem 0;
        background-color: $color-white;
    }

}

div.map-search-popover {
    box-shadow: 1px -2px 3px 0 #00000029;
    border: none;
    border-radius: 2px;
    top: 57.2812px;
    
    ul {
        box-shadow: 0 3px 6px #00000029;
        padding: 1.25rem 0 1.125rem 0;
    }
}

div[data-reach-combobox-popover] ul li, .place-autocomplete-box .custom-box .item {
    &[data-highlighted], &:hover {
        background: $color-light-grey-bg;
    }

    cursor: pointer;
    padding: 0.5rem 0.875rem;

    .cb-option-inner {
        display: flex;
        color: $color-grey-base;

        .place-icon {
            display: block;
            margin-right: 0.375rem;
            width: 1.25rem;
        }

        & > div {
            font-size: get-font('medium');
            line-height: 1.1875rem;
        }

        span[data-suggested-value] {
            font-weight: 400;
        }

        span[data-user-value] {
            color: $color-black;
        }

        .secondary-text {
            margin-top: 0.3125rem;
            font-size: get-font('small');
            line-height: 0.9375rem;
        }
    }
}

div[data-reach-combobox-popover].map-search-popover ul li, .place-autocomplete-box .custom-box .item {
    &[data-highlighted], &:hover {
        background: $color-primary;

        .cb-option-inner {
            color: $color-white;

            span[data-user-value], span {
                color: $color-white;
            }
        }
    }
}

.autocomplete-focused {
    .inner {
        &::after {
            position: absolute;
            content: "";
            width: 95%;
            height: 1px;
            background-color: get-color('grey', 'dark');
            bottom: 0;
            transform: translate(2%);
        }
    }
}

/**
Mobile autocomplete box
*/
.search-page-top {
    .place-autocomplete-box {
        width: calc(100% - 2.625rem);
        display: flex;
        align-items: center;
        margin-right: 0.375rem;
        position: relative;

        .inner {
            height: 2.25rem;
            border-radius: 0;
            box-shadow: 0 0 0 1px $color-grey-light;
            background: $color-light-grey-bg;

            input {
                padding: 0 2.5rem 0 2.5rem;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                background: $color-light-grey-bg;
            }

            .search-icon-cont {
                i {
                    font-size: 1.25rem;
                }
            }

            & > span.clear-search {
                right: 0.675rem;

                .icon-location {
                    display: inline-block;
                    font-size: get-font('x-large');
                }

                .cm-icon {
                    color: $color-primary;
                }
            }
        }

        .custom-box {
            top: 2.25rem;
        }

        &.autocomplete-focused {
            .inner {
                background: $color-white;
                box-shadow: 0 0 0 1px $color-primary;
                position: relative;

                input {
                    background: $color-white;
                }

                &::after {
                    @media only screen and (max-width: $tablet-portrait-breakpoint) {
                        height: 0;
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
}
