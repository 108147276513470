select {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    background-color: transparent;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    option {
        font-size: 18px;
        background-color: #ffffff;
    }

    option:before {
        content: ">";
        font-size: 20px;
        display: none;
        padding-right: 10px;
        padding-left: 5px;
        color: #fff;
    }

    option:hover:before {
        display: inline;
        background-color: get-color('primary', 'blue');
    }
}
