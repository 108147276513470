@import '../../sass/variables';

.app-header {
    /**
    *   Header fonts size changes in different
    *   way based on design requirements
    **/
    position: absolute;
    background-color: transparent;
    width: 100%;
    z-index: 900;
    @include fluid-type(920px, 1920px, 10px, 14px);

    @media only screen and (max-width: $mobile-breakpoint) {
        @if '.posting-top' {
            .top {
                display: none;
            }
        }
    }
    .top {
        color: $color-header-top-text;
        background: $color-header-top;
        height: 2.5rem;
        line-height: 0.9375rem;

        a {
            text-decoration: none;
            color: $color-header-top-text;

        }

        .contact-phone {
            a {
                display: inline-flex;
                align-items: center;
                font-size: 0.75rem;
            }
        }

        .icon-phone {
            font-size: 1.25rem;
            margin-right: 0.675rem;
        }

        .curr-lang {
            display: flex;
            align-items: center;
        }

        .container {
            display: flex;
            align-items: center;
            height: 2.5rem;
            width: 100%;
            justify-content: space-between;
        }

        @media screen and (min-width: $desktop-breakpoint) {
            .new-post-button {
                display: none;
            }
        }

        // dropdowns language and currency switchers
        .cm-dropdown {
            font-size: 1em;
            line-height: 1.1875;
            margin-left: 1rem;

            &.currency-picker {
                min-width: 5rem;

                ul li {
                    justify-content: center;
                }
            }

            .selected {
                color: $color-header-top-text;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                padding: 0.675rem 1rem;

                &::after {
                    //@TODO remove comment if need dropdown arrow down
                    //@include triangle(bottom,5px, 5px, $color-header-top-text);
                    margin-left: 1rem;
                }
            }

            & > ul li {
                background: #fff;
                color: $color-black;
                padding: 0.675rem 1rem;
                box-shadow: 0 0 3px #00000029;
                cursor: pointer;
                position: relative;
                z-index: 1;

                img {
                    display: none;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: transparent;
                    right: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    z-index: -1;
                    transition: background-color 0.3s ease-out;
                }

                @media (hover: hover) {
                    &:hover {
                        &:after {
                            background: rgba($color: $color-black, $alpha: 0.07);
                        }
                    }

                }
                @media only screen and (max-width: $mobile-breakpoint) {
                    width: 75px;
                    padding: 0.5rem 0.6rem;
                }
            }

            &:first-child {
                margin-left: 0;
            }

        }

        // Mobile styles
        @media only screen and (max-width: $mobile-breakpoint) {

            display: flex;
            align-items: center;
            height: 2.8rem;
            background-color: #fff;
            position: relative;

            .contact-phone {
                display: none;
            }

            .cm-dropdown {
                display: flex;
                align-items: center;
                margin-left: 0;

                &.currency-picker {
                    margin-left: 0.95rem;
                }

                .selected {
                    color: black;
                    padding: 0;

                    img {
                        display: none;
                    }
                }
            }

            .curr-lang {
                flex-direction: row-reverse;
                font-size: 14px;
            }

            .new-post-button {
                position: relative;
                background-color: $color-light-blue;
                font-size: 0.6rem;
                padding: 0.5rem 1rem 0.5rem 2.4rem;
                text-transform: uppercase;
                border-radius: 5px;

                &:before {
                    position: absolute;
                    width: 1.5rem;
                    height: 1.2rem;
                    content: "";
                    display: block;
                    background: url(./../../images/icons/add-post.svg) no-repeat;
                    left: 5%;
                    bottom: 13%;
                }
            }

            &::after {
                position: absolute;
                content: "";
                width: 93%;
                transform: translate(3.7%);
                height: 1px;
                bottom: 0;
                background-color: $color-light-grey-border;
                z-index: 1;
            }
        }

        @media only screen and (max-width: 403px) {
            height: 3.2rem;

            .new-post-button {
                padding: 0.2rem 1rem 0.2rem 2.4rem;
                width: 9rem;
                font-size: 8px;

                &:before {
                    bottom: 18%;
                    left: 6%;
                }
            }
        }
    }

    .bottom {
        padding: 0.75rem 0;
        font-size: 1.25em;
        line-height: 1.5em;
        position: relative;
        background: unset;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .left-side, .right-side {
                display: flex;
                align-items: center;
            }

            nav {
                display: flex;
                align-items: center;

                .hamburger {
                    cursor: pointer;
                    display: none;
                    @media only screen and (max-width: $mobile-breakpoint) {
                        display: flex;
                        justify-content: center;

                        img {
                            height: 1.3625rem;
                        }
                    }
                }

                a {
                    @media only screen and (max-width: $mobile-breakpoint) {
                        display: flex;
                        margin-left: 0.625rem;
                    }
                }
            }

            .right-side {
                & > a {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                    padding: 0.8125rem 1.1875rem;
                }

                .btn {
                    margin-right: 1.875rem;
                }

                @media only screen and (max-width: $mobile-breakpoint) {
                    .btn {
                        display: none;
                    }
                }

            }
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            display: flex;
            height: 3.7rem;
            background: $color-white;
        }
    }

    @mixin underline {
        position: absolute;
        content: '';
        width: 50%;
        height: 4px;
        bottom: 0;
        background: $color-orange;

        animation: scale-up-hor-left 0.4s
        cubic-bezier(0.39, 0.575, 0.565, 1) both;

        @-webkit-keyframes scale-up-hor-left {
            0% {
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
            }
            100% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
            }
        }
    }

    .sub {
        display: none;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        position: sticky;
        z-index: 12;
        top: 0;
        box-shadow: 0 3px 6px rgba($color-black, 0.29);

        .sub {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $color-grey-dark;

            .sub-container {
                display: grid;
                grid-template-columns: 1fr 0fr 1fr;
                align-items: center;
                width: 100%;
                color: white;
                text-transform: uppercase;
                font-size: 0.75rem;

                .options {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-grey-light;
                    width: 100%;
                    height: 2.4rem;
                }

                .separator {
                    height: 2rem;
                    width: 1px;
                    background-color: $color-grey-base;
                    display: block;
                }

                .rent {
                    &::after {
                        @include underline;
                        right: 0;
                    }
                }

                .sell {
                    &::after {
                        @include underline;
                        left: 0;
                    }
                }
            }
        }
    }

    .right-side-icons {
        display: flex;
        align-items: center;
        position: relative;

        & > a {
            padding: 0;
            display: none;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            .icon-phone {
                display: flex;
                align-items: center;
                background-color: $color-main-green;
                color: white;
                height: 1.5rem;
                padding: 0.9rem 0.3rem;
                border-radius: 4px;
            }

            .avatar-icon {
                margin-left: 0.7rem;
            }
        }
        .close-login-modal {
            display: none;
        }
        @media only screen and (max-width: $mobile-breakpoint) {
            .close-login-modal {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.6875rem;
                height: 1.6875rem;
                background-color: white;

                i {
                    color: $color-orange;
                }
            }
        }
    }
}

.default_header {
    background: $color-white;
    position: relative;

    @media screen and (min-width: $desktop-breakpoint) {
        .bottom {
            box-shadow: 0 3px 6px rgba($color-black, 0.29);
        }
    }
}

.sticky_header_homepage {
    position: sticky;
    top: 0;
    background: $color-white;

    @media screen and (min-width: $desktop-breakpoint) {
        position: fixed;

        .bottom {
            box-shadow: 0 3px 6px rgba($color-black, 0.29);
        }
    }
}

.sticky_header {
    position: sticky;
    top: 0;
    background: $color-white;
    @media screen and (min-width: $desktop-breakpoint) {
        .bottom {
            box-shadow: 0 3px 6px rgba($color-black, 0.29);
        }
    }
}

.header-account {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
}

.lang-hy, .lang-ru, .lang-en {
    .left-side ul li a {
        color: $color-white;

        &:hover {
            color: $color-white;
        }
    }

    .right-side {
        .btn-primary {
            background-color: $color-orange;
        }
        .right-side-icons {
            @media screen and (min-width: $desktop-breakpoint) {
                .default-avatar svg path {
                    fill: $color-white;
                }
            }
        }
    }
}

.lang-hy.default_header, .lang-ru.default_header, .lang-en.default_header {
    .left-side ul li a {
        color: $color-black;

        &:hover {
            color: $color-black;
        }
    }
    .right-side {
        .btn-primary {
            background-color: $color-main-green;
        }
    }
    .right-side-icons {
        @media screen and (min-width: $desktop-breakpoint) {
            .default-avatar svg path {
                fill: $color-main-green;
            }
        }
    }
}

.lang-hy.sticky_header,
.lang-ru.sticky_header,
.lang-en.sticky_header,
.lang-hy.sticky_header_homepage,
.lang-ru.sticky_header_homepage,
.lang-en.sticky_header_homepage
{
    .left-side ul li a {
        color: $color-black;

        &:hover {
            color: $color-black;
        }
    }
    .right-side {
        .btn-primary {
            background-color: $color-main-green;
        }
    }
    .right-side-icons {
        @media screen and (min-width: $desktop-breakpoint) {
            .default-avatar svg path {
                fill: $color-main-green;
            }
        }
    }
}
