@import '../../sass/variables';
$element-height: 53px;

.subscription-box {
    display: flex;

    input {
        line-height: 1.1875;
        height: $element-height;
        padding: 1rem .5rem;
        font-size: 16px;
        width: inherit;

        &:focus-visible {
            outline: none;
        }

        &::placeholder {
            font-size: $input-placeholder-font-size;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            padding: 1rem;
        }
    }

    button {
        height: $element-height;

        i {
            font-size: 25px;
        }
    }

    @media only screen and (min-width: 1024px) {
        margin-bottom: 1rem;
        span {
            position: absolute;
        }    
    }
   
    @media only screen and (max-width: $mobile-breakpoint) {
        .email-subscription-mb {
            opacity: 1;
            visibility: visible;
        }
        .email-subscription-d {
            opacity: 0;
            visibility: hidden;
        }
    }
    @media only screen and (min-width: $desktop-breakpoint) {
        .email-subscription-mb {
            opacity: 0;
            visibility: hidden;
        }
        .email-subscription-d {
            opacity: 1;
            visibility: visible;
        }
    }
}
