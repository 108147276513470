.btn {
    display: inline-block;
    position: relative;
    cursor: pointer;


    vertical-align: middle;
    align-items: center;
    text-align: center;

    box-sizing: border-box;
    /********** default appearance reset *************/
    user-select: none;
    outline: none;
    border: 0;

    background: transparent;
    color: currentColor;
    white-space: nowrap;

    /******** TEXT ***********/
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    text-transform: uppercase;

    overflow: hidden;
    // @TODO neeed to discuss animations an transitions
    transition: all 0.3s ease-out;
    z-index: 1;

    &:hover , &:focus{
        outline: none;
        border: none;
    }
    padding: 0.75rem 1.5rem 0.6875rem 1.5rem;
    &.btn-primary{
        background: $color-primary;
        color: #ffffff;
        border-radius: $border-radius-main;
        &:disabled{
            background: rgba($color-primary, 0.6);
        }
    }
    &.btn-outline-primary{
        border: 1px solid get-color("primary", "first");
        background-color: #ffffff;
        color: get-color("primary", "first");
        border-radius: $border-radius-main;
    }
    &.btn-loader {
        .btn-content{
            opacity: 0;
        }
    }
    &.btn-green{
        color: $color-green;
    }
    &.btn-primary-color{
        color: get-color("primary", "first");
    }
    &.btn-blue{
        color: get-color("primary", "first");
    }
    &.btn-md{
        font-size: 1rem;
        line-height: 1.1875;
    }
    // disabled state
    &:disabled{
        cursor: default;
        opacity: .6;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        background-color: transparent;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: background-color 0.3s ease-out;
    }
    &.btn-secondary{
        background: $color-grey-base;
        color:  #ffffff
    }
    &.btn-warning{
        background: $color-orange;
        color:  #ffffff
    }

    &.btn-red{
        background: $color-pink-main;
        color:  #ffffff
    }

    &.btn-bordered{
        &.btn-green{
            box-shadow: 0 0 0 1px inset $color-primary;
        }

        border-radius: 2px;
    }
    /****************
        States
    *****************/
    @media (hover:hover){
        &:hover:not(:disabled){
            &:after{
                background-color: rgba($color: #000000, $alpha: 0.16);
            }
        }
        &.btn-transparent{
            &:hover:not(:disabled){
                &:after{
                    background-color: rgba($color: #000000, $alpha: 0.07);
                }
            }
        }
        &.btn-bordered{
            &.btn-green{
                &:hover:not(:disabled){
                    color: #ffffff;
                    &:after{
                        background-color: $color-primary;
                    }
                }

            }
        }

    }

    @media only screen and (max-width:480px){
        padding: 0.75rem;
    }
}

// To extend ui button wrap your element in extend-ui class
.extend-ui {
    .ui.button {
        &.main-green {
            color: $color-main-green !important;

            &.main-green-btn {
                box-shadow: 0 0 0 1px $color-main-green inset !important;
            }
        }
    }
}
