.ui.checkbox {
    input:checked {
        &:focus{
            & ~ .box:after, & ~ label:after {
                color: #fff;
            }

            & ~ .box:before, & ~ label:before {
                background-color: $color-main-green;
                border-color: $color-main-green;
            }
        }
        & ~ .box:after, & ~ label:after {
            color: #fff;
        }

        & ~ .box:before, & ~ label:before {
            background-color: $color-main-green;
            border-color: $color-main-green;
        }
    }
    &.radio{
        input:checked{
            & ~.box:after, ~label:after{
                background-color: $color-primary;
            }
            &:focus{
                & ~.box:after, ~label:after{
                    background-color: $color-primary;
                }    
            }
            
        }
    }
}
