$input-container-padding: 0.5rem 0.675rem 0.675rem 0.675rem;
$input-rounded-radius: 4px;
$input-font-size: 0.875rem;
$input-line-height: 1rem;
$input-placcholder-color: get-color("grey", "base");
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"]{
    display: inline-block;
    position: relative;
    padding: $input-container-padding;
    font-size: $input-font-size;
    vertical-align: middle;

    &.rounded {
        border-radius: $input-rounded-radius;
    }

    &.input-lg {
        // @TODO need to disuss with designer too many dofferent paddings
        padding: 1rem;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $input-placcholder-color;
        font-size: inherit;
        line-height: inherit;
    }


}

textarea {
    resize: none;
    overflow: hidden;
}

input,
textarea {
    border: 1px solid get-color("grey", "base");
}

@media only screen and (max-width: $mobile-breakpoint) {
    .validation-form-group {
        margin-bottom: 1.25rem;
    }
}

.validation-form-group {
    margin-bottom: .5rem;

    .error-message {
        font-size: 0.75rem;
        text-align: left;
        color: $color-pink-main;
        display: block;
        margin-top: 0.125rem;
        line-height: 0.9375rem;
    }

    .form-row {
        margin: 0;
        width: 100%;
        height: 3rem;

        input {
            height: 3rem;
            ::placeholder {
                text-transform: capitalize;
            }
        }

        a {
            color: get-color('primary', 'first');
            margin-left: 3px;
            font-size: 0.875rem;
        }
    }
}

.ui.input.focus>input, .ui.input>input:focus{
    border: 1px solid $color-primary;
}
