.terms{
    margin: 5rem;

    h1{
        text-align: center;
        margin-bottom: 5rem;
    }

    p{
        margin: 1rem 0;
    };
}
