.home-page {
    margin-bottom: 0.875rem;
}

.home-banner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(at -30% 100%, #0D8272, #32B2A1, #0D8272 70%);
    
    .banner-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
            
        .banner-icons {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 95%;
            margin-top: 2rem;

            @media screen and (min-width: $tablet-portrait-breakpoint) {
                justify-content: center;
            }
            
            @media screen and (min-width: $desktop-breakpoint) {
                margin-top: 11.5rem;
            }

            .banner-icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;
                transition: background-color 0.5s;
                
                .banner-link-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                
                .banner-icon-image {
                    display: block;
                    width: 2rem;
                }

                .banner-icon-text {
                    font-size: get-font('small');
                    font-weight: get-font-weight('bold');
                    text-transform: uppercase;
                    color: $color-white;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                @media screen and (min-width: $tablet-portrait-breakpoint) {
                    margin-right: 0.5em;
                    padding: 0.75rem;

                    &:last-child {
                        margin-right: 0;
                    }

                    .banner-link-content {
                        flex-direction: row;
                    }

                    .banner-icon-text {
                        font-size: get-font('medium');
                        margin-left: 0.4em;
                    }

                    .banner-icon-image {
                        width: 3rem;         
                    }
                }
                    
                @media screen and (min-width: $desktop-breakpoint) {
                    margin-right: 2.2em;
                    padding: 0.75rem;

                    &:last-child {
                        margin-right: 0;
                    }
                        
                    .banner-icon-image {
                        width: 4.5rem;         
                    }
                        
                    .banner-icon-text {
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .banner-text-container {
            margin-top: 1.5rem;
            margin-bottom: 3.5rem;
            
            .banner-text {
                font-size: 0.95rem;
                text-transform: uppercase;
                color: $color-white;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;

                .banner-text-right {
                    margin-left: 0.3em;
                }
                
                .banner-text-icon {
                    display: block;
                    width: 1.7rem;
                }
            }

            @media screen and (min-width: $tablet-portrait-breakpoint) {
                .banner-text {
                    font-size: 1.2rem;
                    
                    .banner-text-icon {
                        width: 2.2rem;
                    }
                }
            }

            @media screen and (min-width: $desktop-breakpoint) {
                margin-top: 2.3rem;
                margin-bottom: 0;

                .banner-text {
                    font-size: 2.125rem;
                    
                    .banner-text-icon {
                        width: 4rem;
                    }
                }
            }
        }
    }
}

.search-input-wrapper {
    width: 90%;
    position: absolute;
    bottom: -1.7rem;
    
    .inner, input {
        border-radius: 2rem;
    }

    @media screen and (min-width: $desktop-breakpoint) {
        position: static;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        width: 70%;
        
        .inner, input {
            border-radius: 0.5rem;
        }
    }
    
    .search-input-bar.d-flex {

        .search {
            border-radius: 0;
        }

        .btn {
            font-size: 1.25rem;
            line-height: 1.5rem;
            padding: 1.125rem 2.6875rem;
            overflow: initial;

            @media only screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            i.search {
                color: $color-green !important;
            }
        }

    }
}

#how-help {
    position: relative;

    h2 {
        text-align: center;
        margin-top: 2.6875rem;
        margin-bottom: 3.375rem;
        white-space: pre-line;
        line-height: 2.8125rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            white-space: normal;
            font-size: 1.5rem;
            line-height: 2.6875rem;
        }
    }
}

#how-help, #plans {
    padding: 0 10px;
}

.help-card-container {
    flex-wrap: wrap;
    margin-bottom: 2.75rem;

    .help-card {
        .help-card-image-section {
            display: flex;

            img {
                height: 4.625rem;
            }
        }

        .help-card-search-button::first-letter {
            text-decoration: line-through;
        }
    }

    @media only screen and (min-width: 760px) and (max-width: 920px) {
        .help-card {
            margin-top: 3.125rem;
        }
    }

    .help-card:nth-child(2) {
        margin-left: 3.625rem;
        margin-right: 3.625rem;
    }

    .help-card {
        p {
            margin-bottom: 2.4375rem;
        }
    ;
    }

}

.help-card {
    width: 18.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        margin-right: 0;
    }

    p {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        margin-top: 1.25rem;
        text-align: center;

        @media screen and (min-width: $desktop-breakpoint) {
            min-height: 5.5rem;
        }
    }

    h4 {
        margin-top: 1.4375rem;
        font-size: 1.5rem;
        line-height: 1.8125rem;
        white-space: nowrap;
    }

    @media only screen and (max-width: $tablet-portrait-breakpoint) {
        margin-top: 3.125rem;
    }
}

.container .box:nth-child(1) {
    grid-column: span 2;
    grid-row: span 1;
}

.container .box:nth-child(5) {
    grid-column: span 3;
    grid-row: span 1;
}

.scroll-viewBox {
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 2.3125rem;
    margin-top: 1.5rem;

    .swipe-cont {
        display: flex;
        flex-direction: column;
    }

    .col {
        margin: 5px;

        div {
            overflow: hidden;
        }

        &:hover {

            img {
                transform: scale(1.5);
                transition: all .2s ease-in-out;
            }
        }
    }

    .col img {
        transition: all .2s ease-in-out;
        height: 160px;
    }

    .row {
        display: flex;
    }
}

.scroll-viewBox::-webkit-scrollbar {
    display: none;
}

.layout-home {
    .footer-container {
        min-height: 18.5rem;
        background-size: contain !important;
        justify-content: space-between;

        .widget-column {
            align-self: flex-end;
            min-height: 14rem;

            @media only screen and (max-width: 1024px) {
                align-self: initial;
                min-height: initial;
            }
        }

        @media only screen and (max-width: 1024px) {
            min-height: 50rem;
            justify-content: flex-start !important;
            background-size: 100%;
            background-position-y: -6rem !important;

            .terms-conditions-widget {
                display: none;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            min-height: 18rem;
        }

        @media screen and (max-width: 600px) {
            background-position-y: 0 !important;
        }

        @media screen and (max-width: 425px) {
            min-height: 24rem;
            background-position-y: 4rem !important;
        }

        @media screen and (max-width: $mobile-small) {
            min-height: 23rem;
        }
        @media screen and (max-width: $mobile-extra-small) {
            min-height: 21.7rem;
        }
    }

    .footer-bottom {
        @media only screen and (min-width: 1024px) {
            .center-copy {
                margin-right: 13%;
            }
        }
        .social-links {
            flex: 0;

            @media only screen and (max-width: 1024px) {
                flex: 1;
            }
        }

        .terms-and-cond {
            display: none;
            margin-bottom: 2px;

            @media only screen and (max-width: 1024px) {
                display: flex;

                ul {
                    flex: 1;
                    margin-left: 0;

                    li {
                        a {
                            color: get-color('grey', 'dark');
                            font-size: 0.675rem;
                            white-space: nowrap;
                        }
                    }
                }

                .terms-and-conditions {
                    display: inline-block;
                    text-align: right;
                }
            }
        }

        @media only screen and (max-width: 600px) {
            height: auto;
        }
    }
}
