.draw-container{
    position: absolute;
    top:0 ;
    z-index: 1;
    width: 100%;
    height: 100%
}
.map-buttons-container{
    position: absolute;
    z-index: 1;
    bottom: 3.75rem;
    right:1rem;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width:$mobile-breakpoint) {
        bottom: auto;
        right: 1rem;
        top: 0.75rem;
        bottom: auto;
    }

}
.search-page-main{
     .map-container{
        width: 56.25%;
        position: relative;
        height: 100%;
        @media only screen and (max-width:$mobile-breakpoint){
            width: 100%;
            position: fixed;
            height: calc(100% - #{$header-mobile-height + $filters-mobile-height});
            top: $header-mobile-height + $filters-mobile-height;

        }
    }
    .results-wrapper {
        .mobile-selected-marker {
            display: none;
        }
        @media only screen and (max-width:$mobile-breakpoint){
            &.mobile-is-post-view {
                .map-container {
                    visibility: hidden;
                }

                .scrolling-view-port {
                    display: flex;
                }
            }

            &.mobile-sort{
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                background: #ffffff;
                z-index: 12;
            }

            .mobile-selected-marker {
                position: absolute;
                bottom: 0;
                background: #ffffff;
                left: 0;
                width: 100%;
                z-index: 1000;
                display: block;

                .post-card {
                    display: flex;
                    margin: 0;

                    .card-box {
                        display: flex;
                    }

                    .thumb-container {
                        width: 33.333333%;
                        padding-bottom: 24%;

                        .post-image {
                            background-size: cover;
                        }

                        @media screen and (max-width: 600px) {
                            min-width: 10rem;
                            i {
                                font-size: 4rem;
                            }
                        }
                    }

                    .content {
                        flex: 1;
                        border-bottom: none;

                        @media screen and (max-width: 530px) {
                            width: 150px;

                            .info-item {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }

            .scrolling-view-port{
                display: none;
            }
        }

    }

    .search-page-actions{
        position: fixed;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2rem;
        font-size: 1.25rem;
        box-shadow: 0px 3px 6px rgba(#000000, 0.29);
        display: flex;
        width: 7rem;
        background: #ffffff;
        justify-content: center;
        align-items: center;
        padding: 0.375rem 0;

        ul{
            display: flex;
            li{
                padding: 0.375rem 0.75rem;
                color: $color-primary;
            }

            &.multiple-actions {
                li {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid get-color('grey', 'base');

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }
        @media only screen and (min-width:$desktop-breakpoint){
            display: none;
        }
    }
}

.results-wrapper {
    height: calc(100vh - #{$search-page-top});
    // Mobile styles
    @media only screen and (max-width:$mobile-breakpoint){
        flex-direction: column;
        height: auto;
    }
}

.map-buttons-container{

    & > span{
        background-color:#ffffff;
        border-radius:10px ;
        color: $color-alternate-yellow;
        cursor: pointer;
        box-shadow: 0px 3px 6px rgba(#000000, 0.29);
        padding: 0.5rem;
        width: 3.5rem;
        height: 3.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        i{
            font-size: 2.5rem;
        }
        img{
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

a[href^="http://maps.google.com/maps"]{display:none !important}

.gmnoprint ,.gmnoprint + div {
    display:none;
}

.mobile-scroll-disabled{
    @media only screen and (max-width:$mobile-breakpoint){
        .results-wrapper{
            display: none !important;
        }
    }
}
