.scrolling-view-port {
    position: relative;
    max-width: 43.75%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width:$desktop-breakpoint) {
        overflow-y: overlay;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 6px;
            background: transparent !important;
        }
        &::-webkit-scrollbar-button {
            display: none;
            background: transparent!important;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.4);
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        &::-webkit-scrollbar-track-piece {
            background: transparent !important;


        }
    }
    // Loading state
    &.loading{
        .post-list {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 100;
                opacity: .6;
                background-color: #FFF
            }
        }
    }
    .post-list {
        position: relative;
        padding: 1.125rem 1.875rem 0 1.875rem;
        flex: 1;
        h1 {
            font-size: get-font('x-large');
            margin-bottom:0.875rem;
            line-height: 1.8125rem;
            text-overflow: ellipsis;
            overflow:hidden;
            white-space: nowrap;
        }

        .information {
            display: flex;
            color: $color-grey-base;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            font-weight: 500;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                color: $color-black;
                font-size: 0.875rem;
                align-items: center;

                .sort {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: get-font('small');
                        color: $color-grey-base;
                        margin-right: 0.375rem;
                    }

                    .cm-dropdown{
                        cursor: pointer;

                        .selected{
                            color: $color-primary;
                            padding: 0.25rem 0 0.25rem 0.875rem;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            font-size: get-font('small');

                            &:after{
                                content: "\e947";
                                display: block;
                                color: $color-primary;
                                margin: 0 0.75rem;
                                transform: none;
                                @include icoomoon-tpl();
                                line-height: 0.5;
                            }

                        }

                        .selected-option {
                            background: $color-light-grey-bg;
                            border: 1px solid $color-grey-light;
                            border-radius: 13px;
                        }

                        ul{
                            left: auto;
                            background: #fff;
                            box-shadow: 0 3px 6px rgb(0 0 0 / 29%);
                            right: 0;
                            width: auto;
                            min-width: 100%;
                            top: calc(100% + 10px);
                            padding: 0.75rem 0;
                            li{
                                padding : 0.75rem 1.375rem;
                                white-space: nowrap;
                                color: $color-primary;
                                @media only screen and (hover:hover){
                                    &:hover{
                                        background: $color-list-item-hover;
                                    }
                                }

                            }
                            &::before{
                                position: absolute;
                                top: -9px;
                                right: 1.675rem;
                                @include triangle(top,8px, 8px, $color-grey-light);
                            }
                            &:after{
                                position: absolute;
                                top: -8px;
                                right: 1.675rem;
                                @include triangle(top,8px, 8px, #fff);
                            }
                        }

                    }

                    @media screen and (max-width: 400px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        margin: 15px 0 15px 0;
                    }

                }

                .pagination {
                    display: flex;
                    justify-content: space-between;
                    margin-left: 1.1rem;

                    p {
                        margin-right: 1rem;
                        font-weight: 500;
                    }

                    .prev {
                        margin-right: 2rem;
                    }

                    .cm-icon {
                        font-size: 1rem;
                        color: $color-black;
                        cursor: pointer;
                    }

                    .hidden {
                        display: none;
                    }
                }

                @media screen and (max-width: 540px) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: 10px;

                    .pagination {
                        margin-left: unset;
                    }
                }
            }
        }

        .posts {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    // Mobile styles
    @media only screen and (max-width: $mobile-breakpoint){
        width: 100%;
        max-width: 100%;
        overflow-y: visible;
        overflow-x: hidden;
        padding: 1.125rem 0 0 0;
    }
}

.posts-page {
    display: grid;
    justify-content: end;
}

.layout-secondary {

    .footer-box-container {
        padding: 1.125rem 1.875rem 0 1.875rem;
    }

    .footer-container {
        min-height: 24rem;
        background-size: cover;
        background-position: top;
        justify-content: flex-start;

        .widget-column:nth-child(2) {
            display: none;
        }

        @include app-footer($footer-list-color);

        .widget-column {

            min-height: unset;

            .widget-menu {
                h4 {
                    display: none;
                }
                ul {
                    display: flex !important;
                    margin: 0 auto !important;
                }
            }
        }

        @media screen and (max-width: 450px) {
            min-height: 18.5rem;
        }

        @media screen and (max-width: 425px) {
            min-height: 16rem;
        }
    }

    .footer-bottom {
        .social-links {
            flex: 1;
        }
    }
}
