@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 100;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 200;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 800;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* armenian */
@font-face {
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN7jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idbxZhVoA.woff2) format('woff2');
    unicode-range: U+0530-058F, U+FB13-FB17, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
