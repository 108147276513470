.form-row{
    margin-bottom: 1.25rem;
    input{
        width: 100%;
        margin-bottom: 0.3125rem;
    }
    .error-message{
        font-size: 0.675rem;
    }
    &.has-errors{
        color: $color-pink-main;
        input{
            border: 1px solid $color-red-main;
        }
    }
}
