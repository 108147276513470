@import '../../sass/variables';

.avatar-wrapper {
    display: flex;
    cursor: pointer;
    position: relative;

    .avatar-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: $color-white;
        opacity: 0.7;
        z-index: 2;
    }

    .profile-first-letter {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: 50%;
        font-size: 1.25rem;
        color: $color-white;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .default-avatar {
        display: flex;
        position: relative;
        color: #d9d6d6;
        @media only screen and (max-width:$mobile-breakpoint){
              max-height: $avatar-size-mobile-size;
        }
    }

    .avatar {
        width: $account-icon-size;
        height: $account-icon-size;
        object-fit: cover;
        border-radius: 50%;

        @media only screen and (max-width: $mobile-breakpoint) {
            height: $avatar-size-mobile-size;
            width: $avatar-size-mobile-size;
        }
    }
}

.app-header{
    .right-side-icons{
        .avatar-wrapper{
            display: inline-flex;

            .profile-first-letter{
                @media only screen and (max-width:$mobile-breakpoint){
                    width: $avatar-size-mobile-size;
                    height: $avatar-size-mobile-size;
                    font-size: 0.875rem;
                    font-weight: bold;
                }
            }
        }
    }
}
