.post-card {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 3px #00000029;
    color: $color-black;
    margin-top: 0.625rem;
    cursor: pointer;
    position: relative;

    .post-image{
        background-color: $color-snowflake;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.search {
        @media only screen and (min-width: $mobile-breakpoint) {
            width: 48%;
        }
    }

    &.wish-list,&.my-posts {
        display: flex;
        flex-direction: column;
        border-bottom: 4px solid get-color('primary', 'first');

        .thumb-container {
            padding-bottom: 11.6%;
            width: 27.67%;
            img.post-card-img{
                position: relative;
            }
        }

        &.card-skeleton {
            .content {
                flex-direction: column;
                padding: 0 0.675rem;
            }

            .thumb-container {
                @media screen and (max-width: $mobile-breakpoint) {
                    width: 50%;
                }
            }
        }

        .content{
            flex:  1 1 auto;
            display: flex;
            flex-direction: row;
            padding: 0.675rem 0;

            .post-content{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 1 auto;
                width: 65%;
                padding: 0 1.5rem 0.25rem 0.675rem;
            }

            @media only screen and (max-width: $tablet-portrait-breakpoint) {
                .post-content{
                    flex-direction: column;
                }
            }

            .contact-info{
                display: flex;
                padding: 0.25rem 0.5rem;
                border-left: 1px solid $color-grey-light;

                @media only screen and (max-width: $tablet-portrait-breakpoint) {
                    display: none;
                }

                div{
                    margin-right: 1rem;
                }

                .owner-info{
                    color: $color-primary;
                    font-size: 0.75rem;
                    line-height: 0.9375rem;
                    .phone-numbers-cont{
                        display: flex;
                        margin-top: 0.4375rem;

                        ul{
                            li{
                                display: block;
                                margin-bottom: 0.5rem;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                i{
                                    margin-right: 0.5rem;
                                }
                                a{
                                    color: $color-black;
                                }
                            }
                        }
                    }
                }

                .owner-thumb{
                    margin-right: 0.675rem;

                    span{
                        display: inline-flex;
                        width: 3.125rem;
                        height: 3.125rem;
                        border-radius: 100%;
                        background: $color-grey-light;
                        overflow:hidden;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        //MOBILE STYLES
        @media only screen and (max-width: $mobile-breakpoint) {
            .card-box {
                flex-direction: column;
            }

            .thumb-container{
                padding-bottom: 41.9%;
                width: 100%;
            }
        }
    }

    &.my-posts{
        .content{
            padding-right: 1rem;

            .types{
                justify-content: start;
                margin-top: 0;

                .property-type{
                    display: inline-flex;
                    align-items: center;
                    text-transform: uppercase;
                    margin-left: 1.125rem;
                }

                .deal-type{
                    margin-left: 1.375rem;
                }
            }

            @media only screen and (min-width: $mobile-breakpoint) {
                .info {
                    justify-content: unset;
                }
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                padding: 0.625rem 0.625rem 0.625rem 0.625rem;

                .post-content {
                    padding: 0;

                    .post-content-types {
                        display: flex;

                        .types {
                            overflow: hidden;

                            .property-type, .deal-type {
                                margin-left: 0.625rem;
                                font-size: 0.625rem;
                            }
                        }
                    }
                }

                .info {
                    justify-content: space-between;
                }
            }
        }
    }

    .thumb-container{
        padding-bottom: 41.9%; // aspect ratio 310X130
        position: relative;

        .cm-skeleton{
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .star-cont{
            height: 1.5625rem;
            width: 1.5625rem;
            display: inline-flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            right: 0.5rem;
            top: 0.5rem;
            overflow: hidden;
            border-radius: 100%;

            img{
                position: relative;
                z-index: 1;
            }

            &:after{
                content: "";
                background: $color-black;
                opacity: 0.5;
                @include layer();
            }
        }

        .img-circle {
            position: absolute;
            display: inline-flex;
            bottom: -0.6rem;
            left: 4%;
            z-index: 2;
            width: 3rem;
            height: 3rem;
            background-color: white;
            border-radius: 50%;

            .user-preview-img {
                position: relative;
                border-radius: 50%;
                margin: auto;

                .avatar {
                    display: block;
                    width: 2.7rem;
                    height: 2.7rem;
                }
            }
        }

        .info-layer {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 2.4rem;
            width: 88%;
            padding: 0.25rem 0.375rem;

            .info-layer-text {
                position: relative;
                font-size: 0.7rem;
                text-transform: uppercase;
                font-weight: 450;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: white;
                z-index: 1;
                padding: 0px 5px 2px 22px;

                .text-building-name {
                    font-weight: 600;
                }
            }

            &:after {
                content: "";
                text-align: left;
                background: $color-black;
                opacity: 0.3;
                @include layer();
            }
        }

        @media only screen and (min-width: 1840px) and (max-width: 2671px) {
            .img-circle {
                width: 3.5rem;
                height: 3.5rem;

                .user-preview-img  .avatar {
                    width: 3.2rem;
                    height: 3.2rem;
                }
            }

            .info-layer {
                height: 2.8rem;
                width: 89%;

                .info-layer-text {
                    padding: 0px 5px 2px 40px;
                }
            }
        }

        @media only screen and (min-width: 1440px) and (max-width: 1839px) {
            .img-circle {
                width: 3.4rem;
                height: 3.4rem;

                .user-preview-img  .avatar {
                    width: 3.1rem;
                    height: 3.1rem;
                }
            }

            .info-layer {
                height: 2.7rem;
                width: 88%;

                .info-layer-text {
                    padding: 0px 5px 2px 35px;
                }
            }
        }

        @media only screen and (min-width: 920px) and (max-width: 1439px) {
            .img-circle {
                width: 3.3rem;
                height: 3.3rem;

                .user-preview-img  .avatar {
                    width: 3rem;
                    height: 3rem;
                }
            }

            .info-layer {
                height: 2.6rem;
                width: 88%;

                .info-layer-text {
                    padding: 0px 5px 2px 26px;
                }
            }
        }

        @media only screen and (min-width: 760px) and (max-width: 919px) {
            .img-circle {
                width: 3.8rem;
                height: 3.8rem;

                .user-preview-img  .avatar {
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }

            .info-layer {
                height: 3rem;
                width: 92%;

                .info-layer-text {
                    padding: 0px 5px 2px 35px;
                }
            }
        }

        @media only screen and (min-width: 640px) and (max-width: 759px) {
            .img-circle {
                width: 3.6rem;
                height: 3.6rem;

                .user-preview-img .avatar {
                    width: 3.3rem;
                    height: 3.3rem;
                }
            }

            .info-layer {
                height: 2.9rem;
                width: 91%;

                .info-layer-text {
                    padding: 0px 5px 2px 33px;
                }
            }
        }

        @media only screen and (min-width: 550px) and (max-width: 639px) {
            .img-circle {
                width: 3.4rem;
                height: 3.4rem;

                .user-preview-img .avatar {
                    width: 3.1rem;
                    height: 3.1rem;
                }
            }

            .info-layer {
                height: 2.7rem;
                width: 91%;

                .info-layer-text {
                    padding: 0px 5px 2px 36px;
                }
            }
        }

        @media only screen and (min-width: 400px) and (max-width: 549px) {
            .img-circle {
                width: 3.4rem;
                height: 3.4rem;

                .user-preview-img  .avatar {
                    width: 3.1rem;
                    height: 3.1rem;
                }
            }

            .info-layer {
                height: 2.7rem;
                width: 90%;

                .info-layer-text {
                    padding: 0px 5px 2px 37px;
                }
            }
        }

        @media only screen and (min-width: 310px) and (max-width: 399px) {
            .img-circle {
                width: 3.2rem;
                height: 3.2rem;

                .user-preview-img  .avatar {
                    width: 2.9rem;
                    height: 2.9rem;
                }
            }

            .info-layer {
                height: 2.5rem;
                width: 90%;

                .info-layer-text {
                    padding: 0px 5px 2px 32px;
                }
            }
        }

        img.post-card-img{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
        }
    }

    .content {
        padding: 0.4rem 0.75rem;
        min-height: 6.5625rem;

        .cm-skeleton{
            width: 100%;
            margin-top: 0.75rem;
        }

        .price {
            margin-top: 0.45rem;
            font-size: get-font('large');
            line-height: 1.375rem;
            display: flex;
        }

        .address {
            font-size: get-font('small');
            line-height: 0.9375rem;
            color: $color-black;
            @include elpises();
            max-width: 385px;

            @media only screen and (max-width: $mobile-breakpoint) {
                max-width: 100%;
            }
        }

        p {
            margin-top: 0.35rem;
        }

        .types {
            color: $color-main-green;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            margin-top: 0.4375rem;

            & > span {
                text-transform: uppercase;
                display: inline-block;
                font-size: get-font('small');
            }
        }

        .types{
            .deal-type{
                color: $color-grey-base;
                font-weight: 400;
                display: inline-flex;
                align-items: center;
                text-transform: uppercase;

                &:before{
                    content: '';
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50%;
                    margin-right: 0.3125rem;
                }

                @each $color-key, $color-value in $dealColors{
                    &.deal-#{$color-key}{
                        &:before{
                            background-color: $color-value;
                        }
                    }
                }
            }
        }
    }

    .info {
        display: flex;
        margin-top: 0.5rem;
        justify-content: space-between;

        .info-item:first-of-type {
            margin-left: 0;
        }

        .info-item {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            color: $color-black;
            margin-right: 1rem;
            white-space: nowrap;

            i {
                font-size: 1.5rem;
                color: $color-yellow;
                margin-right: 0.5rem;
            }

            &>span {
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .info-item:nth-child(4) {
            overflow: hidden;
        }
    }

    .post-active-status {
        white-space: nowrap;
    }

    .bottom-sheet {
        display: none;
    }

    .post-code {
        position: absolute;
        top: 0;
        left: 0;
    }

    // Mobile styles
    @media only screen and (max-width: $mobile-breakpoint) {
        max-width: 100%;

        &.item-row{
            flex-direction: column;

            .thumb-container{
                width: 100%;
            }

            .content{
                width: 100%;
            }
        }

        .actions {
            display: none !important;
        }

        .bottom-sheet {
            display: flex;
            justify-content: flex-end;
        }

        .card-box {
            width: 100%;
        }
    }
}
