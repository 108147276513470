// Custom drop downs
.cm-dropdown{
    position: relative;
    & > ul{
        left: 0;
        top: 100%;
        position: absolute;
        display: none;
        width: 100%;
        z-index: 2;
        li{
            display: flex;
            align-items: center;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            left: -10px;
            top: 27px;
        }
    }
    .selected{
        &:after{
            transition: transform 0.3s ease-in;
        }
    }
    &.opened{
        & > ul {
            display: block;
        }
        .selected{
            &:after{
                transform: rotate(-180deg);
            }
        }
    }
}