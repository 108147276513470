.confirm-modal{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    & > div {
        max-width: 32.5rem;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.25rem;
        background: #FFFFFF;
        border-radius: 5px;
    }

    .confirm-header {
        font-weight: bold;
        font-size: 1rem;
        color: $color-pink-main;
        border-bottom: 1px solid $color-grey-light;
        padding-bottom: 0.625rem;
    }

    .confirm-text{
        margin-top:  1.25rem;
        margin-bottom: 2.5rem;
        font-size: 1rem;
    }
    .confirm-dialog-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn{
            width: 50%;
            padding: 0.75rem;
            border: 1px solid;
            &:last-child{
                margin-left: 1.25rem;

                @media screen and (max-width: $mobile-small) {

                    margin-left: 0;
                }
            }
        }
    }
 }

.confirm-modal-hidden {
    display: none;
}


