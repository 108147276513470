$local-transition-duration: .3s;
$mobilemenu-backdrop-bg: #333 !default;
$mobilemenu-bg: #fff !default;
$mobilemenu-shadow: 0 0 20px rgba(#000, .5) !default;
$mobilemenu-divider-color: #ebebeb !default;
$mobilemenu-header-height: 80px !default;
$mobilemenu-font-color: #eee !default;
$mobilemenu-font-muted-color: #999 !default;
$mobilemenu-deep-links-bg: #f7f7f7 !default;
$mobilemenu-close-button-default-color: #999 !default;

.mobile-menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: visibility 0s $local-transition-duration;

    .mobile-menu__backdrop {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: $mobilemenu-backdrop-bg;
        opacity: 0;
        transition: opacity $local-transition-duration;
    }

    .mobile-menu__body {
        position: absolute;
        top: 0;
        width: 85%;
        height: 100%;
        background: $mobilemenu-bg;
        box-shadow: $mobilemenu-shadow;
        transition: transform $local-transition-duration;
        display: flex;
        flex-direction: column;
        left: 0;
        transform: translateX(calc(-100% - 30px));
    }
}

.mobile-menu__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

.mobile-menu__content__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mobile-menu__header {
        padding: 0.75rem 0.75rem;

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 2rem;
        }
    }

    .btn.btn-primary.open-announcement {
        background-color: $color-main-blue;
        border-bottom: 1px solid #eee;
    }

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;

        li {
            height: 3.5625rem;
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;

            a {
                color: #000000;
                font-size: 0.875rem;
                text-transform: uppercase;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 2rem;
            }
        }
    }
}

.mobile-menu__title {
    padding: 0 20px;
    flex-grow: 1;
}

.mobile-menu__content__foot {

    .btn.btn-primary.login {
        border: 1px solid $color-green;
        background-color: $color-white;
        color: $color-green;
        border-radius: 0.1rem;
        width: 97%;
    }

    .cm-dropdown {
        .selected {
            align-items: center;
            justify-content: space-between;
            display: flex;
            padding: 1.25rem 0;
        }

        ul {
            position: unset;

            li {
                padding: 0 0 1.25rem 0;
            }
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 2rem;
            //@TODO remove comment for mobile menu currency arrow
            //content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            -moz-transform: scale(0.9999);
            border-width: 5px 5px 0 5px;
            border-color: $color-green transparent transparent transparent;
            margin-left: 1rem;
        }
    }

    .cm-dropdown.opened {
        background-color: #FAFAFA;

        .selected {
            color: $color-green;
        }

        &:after {
            top: 25%;
            transform: rotate(180deg);
        }

    }

    a.mobile-menu-contacts {
        background-color: $color-grey-light;
        padding: 0.9375rem;
        font-size: get-font('small');
        color: $color-black;
        text-align: center;
        align-items: center;
        display: flex;

        i {
            font-size: get-font('x-large');
            margin-right: 0.5rem;
            color: $color-green;
        }
    }
}

.mobile-menu--open {
    visibility: visible;
    transition-delay: 0s;

    .mobile-menu__backdrop {
        opacity: .9;
    }

    .mobile-menu__body {
        transform: translateX(0);
    }

    @media only screen and (min-width: $desktop-breakpoint) {
        display: none;
    }
}

.mobile-menu__close {
    border: none;
    background: transparent;
    height: $mobilemenu-header-height;
    width: $mobilemenu-header-height + 1px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.action_content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
}
