@use "sass:math";
/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` , `left`, `topRight`,  `bottomRight`, `bottomLeft`, `topLeft`
/// @param {Color} $color Triangle color
/// @param {sizeH} $sizeH Horizontal size
/// @param {sizeV} $sizeV Vertical size
@mixin triangle($direction, $sizeH, $sizeV, $color) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    -moz-transform: scale(.9999);

    @if $direction == top {
        border-width: 0 $sizeV $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }
    @if $direction == bottom {
        border-width: $sizeV $sizeH 0 $sizeV;
        border-color: $color transparent transparent transparent;
    }
    @if $direction == left {
        border-width: $sizeV $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }
    @if $direction == right {
        border-width: $sizeV 0 $sizeV $sizeH;
        border-color: transparent transparent transparent $color;
    }
    @if $direction == topRight {
        border-width: 0 $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }
    @if $direction == bottomRight {
        border-width: 0 0 $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }
    @if $direction == bottomLeft {
        border-width: $sizeH 0 0 $sizeV;
        border-color: transparent transparent transparent $color;
    }
    @if $direction == topLeft {
        border-width: $sizeH $sizeV 0 0;
        border-color: $color transparent transparent transparent;
    }
}

@mixin columns($uid: column, $gap: 16px, $columns: 12) {
    @for $i from 1 through $columns {
        .#{$uid}-#{$i} {
            width: math.div(100%, $columns * $i);
        }
        .#{$uid}-offset-#{$i} {
            margin-left: math.div(100%, $columns * $i);
        }
    }
    .row {
        // TODO: get back when config is fixed from back(includes row info)
    }
}

@function strip-unit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {

            @media screen and (min-width: $min-vw) {
                font-size: calc(
                    #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
                    ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
                );
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin icoomoon-tpl() {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin layer() {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

@mixin elpises() {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin app-footer($list-color) {
    flex-direction: column-reverse;

    div {
        width: 100%;
    }
    .row {
        margin: 0;

        ul {
            display: flex;

            li {
                a {
                    color: $list-color;
                }

                &::after {
                    padding: 0 0.3125rem;
                    content: "|"
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

@mixin page-height() {
    height: calc(#{100vh} - #{4.875em});
}

@mixin semi-transparent-bottom-section($background) {
    content: "";
    position: sticky;
    width: 100%;
    height: 2rem;
    left: 0;
    bottom: 0;
    background: $background;
    display: block;
}

@mixin editor-text-styles() {
    strong {
        font-weight: bold !important;
    }

    em {
        font-style: italic !important;
    }

    s {
        text-decoration-line: line-through !important;
    }
}
