$border-grey: 1px solid $color-light-grey-border;

.main-filters {
    .more-filters {
        padding-bottom: 0.675rem;
        margin: 0 auto;

        .more-text {
            padding: 0 0.75rem;
            color: $color-primary;
            text-transform: uppercase;
            font-size: get-font('medium');
            line-height: 1.1875rem;
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media (hover: hover) {
                cursor: pointer;
            }

            span {
                display: inline-block;
                position: relative;
                text-align: center;

                &::after {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0.5rem;
                    @include triangle(bottom, 4px, 4px, $color-primary);
                }
            }
        }

        .more-dropdown {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            width: 100vw;
            z-index: 999;
            background: $color-light-grey-bg;
            padding: 0.75rem 1.125rem 1.625rem 3.125rem;

            .inner {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            .filter {
                min-width: 15.875rem;
                padding: 0;
                margin: 0 1.25rem 1.875rem 0;

                .filter-dropdown {
                    min-width: 100%;
                    right: auto;
                    left: 0;
                    box-shadow: none;

                    &:after, &::before {
                        display: none;
                    }

                    .filter-footer {
                        display: none;
                    }
                }

                &.active {
                    box-shadow: none;
                    background: none;
                }

                .filter-summary {
                    padding: 0;
                    border-right: none;
                    display: block;
                    position: relative;

                    .selected-val {
                        padding-left: 1.25rem;
                        display: flex;
                        align-items: center;
                        background: #fff;
                        border: 1px solid #D9D9DA;
                        border-radius: 4px;
                        justify-content: space-between;
                        height: 2.5rem;
                        @media only screen and (max-width: $tablet-portrait-breakpoint) {
                            height: auto;
                            border: none;
                            background: none;
                            padding-left: 0;
                            font-size: get-font('medium');
                            line-height: 1.1875rem;
                        }

                    }

                    & > div {
                        max-width: 100%;
                    }

                    & > i.cm-icon {
                        position: absolute;
                        bottom: 0.675rem;
                        right: 0.5rem;
                    }
                }

                &.f-text-input,
                &.f-public-code-input {
                    .filter-summary {
                        .selected-val {
                            display: none;
                        }

                        & > .cm-icon {
                            display: none;
                        }
                    }

                    .filter-dropdown {
                        display: block;
                        border: none;
                        padding: 0;
                        position: relative;
                        top: 0;

                        .filter-content {
                            padding: 0;

                            .ui.input {
                                width: 100%;
                            }
                        }
                    }

                    @media only screen and (max-width: $tablet-portrait-breakpoint) {
                        padding-top: 0.675rem;
                        position: relative;
                        padding-bottom: 0.875rem;
                        &::after {
                            content: "";
                            width: calc(100% - 1.5rem);
                            height: 1px;
                            left: 0.75rem;
                            position: absolute;
                            background-color: $color-grey-light;
                            bottom: 0;
                        }
                    }
                }

                // Media Queries
                &.f-single-choice,
                &.f-multiple-choice,
                &.f-state-choice,
                &.f-district-choice {
                    @media only screen and (max-width: $tablet-portrait-breakpoint) {
                        .filter-summary {
                            padding-top: 0.75rem;
                            padding-bottom: 0.375rem;

                            label {
                                font-size: get-font('small');
                                line-height: 0.75rem;
                                margin-bottom: 1px;
                            }

                            border-bottom: 1px solid $color-grey-light;

                            &::after {
                                content: "";
                                display: block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                -moz-transform: scale(0.9999);
                                border-width: 8px 5px 0 5px;
                                border-color: #008272 transparent transparent transparent;
                                position: absolute;
                                bottom: 0.675rem;
                                right: 0.5rem;
                            }

                            &.disabled-filter {
                                &::after {
                                    border-color: get-color('grey', 'base') transparent transparent transparent;
                                }

                                .default-val {
                                    color: get-color('grey', 'base');
                                }
                            }

                            & > i.cm-icon {
                                display: none;
                            }
                        }
                    }
                }

                &.selected {
                    @media screen and (max-width: $tablet-portrait-breakpoint) {
                        .selected-val {
                            color: $color-main-green;
                        }
                    }
                }

                @media only screen and (max-width: $mobile-breakpoint) {
                    margin: 0 15px 9px 0;
                }
            }

            .filters-actions {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                align-self: start;

                a.clear-filters {
                    cursor: pointer;
                    margin-right: 1.5rem;
                    color: $color-primary;
                    display: none;
                    @media only screen and (max-width: $mobile-breakpoint) {
                        display: block;
                    }

                }

                @media only screen and (max-width: $tablet-portrait-breakpoint) {
                    background: #fff;
                    box-shadow: 0 3px 6px #00000029;
                    padding: 0 0.75rem;
                    height: $dropdown-action-bar-height;
                }
            }
        }

        // Active state
        &.active {
            .more-dropdown {
                left: 0;
                display: flex;
                flex-wrap: wrap;

                @media screen and (orientation:landscape) {
                    overflow-y: scroll;
                    max-height: 30vh;
                }
            }
        }


        @media only screen and (max-width: $mobile-breakpoint) {
            padding-left: 0;
        }
    }

    .filter {
        padding-bottom: 0.675rem;
        position: relative;
        min-width: 10.5rem;

        @media only screen and (max-width: $tablet-portrait-breakpoint) {
            min-width: 100vw;
        }

        .filter-summary {
            border-right: $border-grey;
            padding: 0.675rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (min-width: $tablet-portrait-breakpoint) {
                & > div {
                    max-width: 12rem;
                }
            }
            @media (hover: hover) {
                cursor: pointer;
            }

            & > div {
                & > label {
                    font-size: get-font('small');
                    line-height: 1.0625rem;
                    margin-bottom: 0.5625rem;
                    text-transform: uppercase;
                    color: get-color('grey', 'base');
                }

                & > .selected-val {
                    font-weight: 600;
                    line-height: 1.1875rem;
                    font-size: 0.95rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                    white-space: nowrap;

                    .choosen-val {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 80%;
                        white-space: nowrap;
                        text-transform: capitalize;
                    }

                    .defualt-val {
                        text-transform: uppercase;
                    }

                    .selected-count {
                        color: $color-primary;
                        margin: 0 1.675rem 0 0;
                        font-size: get-font('small');
                    }
                }

                & > label, & > span {
                    display: block;

                }
            }

            i {
                margin-left: 1rem;
                color: $color-primary;
                position: absolute;
                right: 7px;
            }

            &.disabled-filter {
                .icon-dropdown-arrow {
                    color: get-color('grey', 'base');
                }

                .default-val {
                    color: get-color('grey', 'base');
                }
            }
        }

        .filter-dropdown {
            min-width: 18rem;

            .mobile-header {
                display: none;
            }

            border: 1px solid #D9D9DA;
            display: none;
            position: absolute;

            box-shadow: 0 3px 6px rgba(#000000, 0.29);
            right: 0;
            top: 100%;

            .filter-content {
                padding: 1.875rem 1.25rem;
                max-height: calc(100vh - 20rem);
                overflow: auto;

                &.district-content {
                    padding: 0;
                    overflow: hidden;

                    .district-choices {
                        .search-bar {
                            padding: 0.8rem 1.25rem;
                            border-bottom: 1px solid #D9D9DA;

                            .ui.input {
                                width: 100%;

                                i {
                                    &::before {
                                        content: "\e900";
                                        @include icoomoon-tpl();
                                    }
                                }
                            }
                        }

                        .district-scroller {
                            max-height: calc(100vh - 24.25rem);
                            padding: 1rem 1.25rem 1.875rem 1.25rem;
                            overflow-y: scroll;

                            h4 {
                                margin: 0.8rem 0 0.6rem;
                            }

                            @media only screen and (min-width: $desktop-breakpoint) {
                                overflow-y: overlay;
                                &::-webkit-scrollbar {
                                    width: 6px;
                                    background: transparent !important;
                                }
                                &::-webkit-scrollbar-button {
                                    display: none;
                                    background: transparent !important;
                                }
                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background: rgba(0, 0, 0, 0.4);
                                }
                                &::-webkit-scrollbar-track {
                                    background: transparent !important;
                                }

                                &::-webkit-scrollbar-track-piece {
                                    background: transparent !important;


                                }
                            }

                            @media screen and (orientation:landscape) {
                                max-height: 50vh;
                            }
                        }

                    }
                }

                @media screen and (orientation:landscape) {
                    max-height: unset;
                }
            }

            &:after {
                position: absolute;
                top: -8px;
                right: 19px;
                @include triangle(top, 8px, 8px, #fff);
            }

            &:before {
                position: absolute;
                top: -9px;
                right: 19px;
                @include triangle(top, 8px, 8px, #D9D9DA);
            }

            ul.choices {
                li {
                    margin-bottom: 1.25rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @each $color-key, $color-value in $dealColors {
                        &.choice-#{$color-key} {
                            .ui.checkbox {
                                label:before {
                                    border-color: $color-value;
                                }

                                input:checked {
                                    & ~ label:after {
                                        background-color: $color-value;
                                    }
                                }

                            }

                        }
                    }

                }
            }

            .filter-footer {
                background: $color-light-grey-bg;
                border-top: 1px solid #D9D9DA;
                padding: 0.675rem;
                display: flex;
                justify-content: end;

                .btn {
                    display: inline-block;
                    margin-left: auto;
                    font-size: get-font('medium');
                    line-height: 1.1875rem;
                    padding: 0.675rem 1.25rem;
                }
            }

        }

        // Active state
        &.active {
            box-shadow: 0px -3px $color-main-green;
            background: $color-white;

            .filter-dropdown {
                display: block;
                border-radius: 4px;
                background: $color-white;
                z-index: 11;
            }

        }
    }

    @media only screen and (min-width: $tablet-portrait-breakpoint) {
        .priority-nav-main {
            .priority-nav-item {
                .filter {
                    .filter-summary {
                        div {
                            width: 100%;

                            .selected-val {
                                max-width: 93%;
                                display: flex;
                                justify-content: space-between;

                                .choosen-val {
                                    max-width: 100%;
                                }

                                .selected-count {
                                    font-size: get-font('small');
                                    color: $color-primary;
                                    margin: 0;
                                }

                            }
                        }
                    }
                }

                &:nth-child(1), &:nth-child(2) {
                    .filter {
                        .filter-dropdown {
                            left: 0;
                            right: auto;

                            &:after {
                                left: 19px;

                            }

                            &:before {
                                left: 19px;

                            }
                        }
                    }
                }
            }
        }
    }
    // Mobile styles
    @media only screen and (max-width: $tablet-portrait-breakpoint) {
        height: auto;
        display: flex;
        align-items: center;
        .more-filters {
            border-right: none;
            padding-bottom: 0;
            display: flex;
            align-items: center;

            &.active {
                .more-text {
                    &:after {
                        content: "\e916";
                        color: $color-yellow;
                    }
                }

                &.filters-applied {
                    .more-text {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &.filters-applied {
                .more-text {

                    &::before {
                        content: "";
                        display: block;
                        background: $color-pink-main;
                        width: 0.675rem;
                        height: 0.675rem;
                        border-radius: 50%;
                        position: absolute;
                        right: 1px;
                        top: 1px;
                        border: 1px solid #fff;
                    }
                }
            }

            .more-text {
                width: 2.25rem;
                height: 2.25rem;
                box-shadow: 0 0 0 1px $color-primary;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 2px;
                position: relative;

                span {
                    display: none;
                }

                &:after {
                    @include icoomoon-tpl();
                    content: "\e944";
                    color: $color-primary;
                    font-size: get-font('x-large');
                }
            }

            .more-dropdown {
                padding: 0;
                width: 100%;
                position: fixed; //need to swicth position fixed as vh has issues on safari
                top: $header-mobile-height + $filters-mobile-height;
                z-index: 9999;
                height: calc(100% - #{$header-mobile-height + $filters-mobile-height});

                .inner {
                    height: calc(100% - 4rem);
                    overflow-y: scroll;
                    scroll-behavior: smooth;
                    background: #fff;
                }

                .filter {
                    width: 100%;
                    padding: 0 0.75rem;
                    margin: 0;

                    &.f-semantic-dropdown {
                        .filter-summary {
                            border-bottom: 1px solid #D9D9DA;
                            padding-left: 0.675rem;
                            padding-bottom: 3px;

                            label {
                                font-size: get-font('x-small');
                                margin-bottom: 1px;
                            }

                            .selected-val {
                                padding-left: 0;
                                font-size: get-font('medium');
                                line-height: 19px; //no need to resize
                                background: none;
                                border: none;
                                height: auto;
                            }
                        }
                    }

                    &.active {
                        z-index: 2;

                        .filter-dropdown {
                            .filter-content {
                                padding: 1.25rem;
                                max-height: calc(100vh - 7.4rem);

                                &.district-content {
                                    overflow: hidden;
                                    padding: 0;

                                    .district-scroller {
                                        max-height: calc(100vh - 14.6rem);

                                        // styles for different IOS versions
                                        @media screen and (min-resolution: 3dppx), (min-resolution: 2dppx), (min-resolution: 1dppx) {
                                            @supports (-webkit-touch-callout: none) {
                                                -webkit-overflow-scrolling: touch;
                                                max-height: calc(100vh - 18rem);
                                            }
                                        }
                                    }
                                }
                            }

                            .mobile-header {
                                display: flex;
                                height: 3.375rem;
                                padding: 0 1.25rem;
                                align-items: center;
                                justify-content: center;
                                border-bottom: 1px solid #D9D9DA;

                                i {
                                    color: $color-primary;
                                }

                                .m-title {
                                    text-transform: capitalize;
                                    font-size: get-font('large');
                                    line-height: 1.5rem;
                                    font-weight: 600;
                                }
                            }

                            display: block;
                            position: fixed;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            z-index: 999;
                            background: #ffffff;
                            border-radius: 0;

                            &::before, &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.search-box {
    display: flex;
    background: $color-light-grey-bg;
    margin: 0 5px 0 5px;

    .search-page-top {
        position: relative;
        display: flex;
        padding-top: 1rem;
        width: 95%;
        border-bottom: $border-grey;
        max-width: calc(100vw - 2.625rem);

        .main-filters {
            flex: 1 1 auto;
            @media only screen and (max-width: $tablet-portrait-breakpoint) {
                justify-content: end;
                max-width: fit-content;
            }
        }

        @media only screen and (max-width: $tablet-portrait-breakpoint) {
            height: $filters-mobile-height;
            padding: 0 1rem;
            flex-direction: row-reverse;
        }
        @media only screen and (min-width: 768px) {
            .place-autocomplete-box {
                display: none;
            }
        }
        @media only screen and (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }

    .search-actions {
        display: flex;
        align-self: center;
        position: relative;
        height: 4.5em;

        div {
            display: flex;
            align-items: center;
        }

        .clear-search {
            margin-right: 5px;
            border-right: $border-grey;
            border-left: $border-grey;
            padding: 0.675rem;
            cursor: pointer;
            @media only screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }

        .save-search-cont {
            padding: 0.675rem;
            cursor: pointer;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            border-left: $border-grey;
        }
        @media only screen and (max-width: $tablet-portrait-breakpoint) {
            height: unset;
            border-left: unset;
        }
    }
}

.priority-nav-root-hidden {
    visibility: hidden;
}

.priority-navigation {
    position: relative;
    bottom: 0;
    width: 0;
}

.priority-nav-main {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100vw;
}

body.mobile-scroll-disabled {
    @media only screen and (max-width: $mobile-breakpoint) {
        overflow: hidden;
    }
}

.jivo-widget-disabled {
    @media only screen and (max-width: $mobile-breakpoint) {
        display: none;
    }
}
