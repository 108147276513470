$mobile-breakpoint: 920px;
$desktop-breakpoint: 921px;
$tablet-portrait-breakpoint: 767px;
$mobile-small: 375px;
$mobile-extra-small: 320px;
$desktop-md-breakpoint: 1200px;
$desktop-lg-breakpoint: 1600px;
$section-header-icon-size: 2.75rem;
$account-icon-size: 42px;

$avatar-size: 2.625rem;
$avatar-size-mobile-size: 2rem;

$grid-cols: 12;
$grid-gap: 0.675rem; // 10px

$color-main-green: #008272;
$color-orange: #FFBD24;
$color-alternate-orange: #FAAD00;
$color-yellow:#FFBD24;
$color-alternate-yellow: #FBAF29;
$color-red-main:#ff1744;
$color-pink-main:#FD5D7C;
$color-grey-base: #B5B5B5;
$color-grey-light: #D9D9Da;
$color-grey-dark: #646464;
$color-grey-alternate: #707070;
$color-black : #000000;
$color-green: #008272;
$color-header-top: $color-black;
$color-header-top-text: #fff;
$color-header-bottom-text: $color-black;
$color-main-purple: #903CE5;
$color-main-blue: #3588FD;
$color-light-blue: #27A9DD;
$color-primary: $color-green;
$color-light-grey-border: #E4E4E5;
$color-light-grey-bg: #FAFAFA;
$color-list-item-hover:#EDF2F5;
$color-white: #FFFFFF;
$subscription-plan-basic: #B5B5B5;
$subscription-plan-vip: $color-alternate-yellow;
$subscription-plan-premium: #108177;
$btn-text-white:#FFFFFF;
$footer-list-color: #0D8272;
$color-snowflake: #F2F5F7;
$color-border: #e9e9e9;

$messengers-icon-colors: (
    'whatsapp': #36d672,
    'telegram': #27a9dd,
    'viber': #834995,
);

$input-placeholder-font-size: 14px;

$colors: (
    "grey": (
        "dark": $color-grey-dark,
        "base": $color-grey-base,
        "light" : $color-light-grey-border

    ),
    "primary": (
        "first": $color-main-green,
        "second": $color-orange,
        "blue": $color-main-blue,
        "green": $color-main-green,
    )
);

$dealColors: (
  'rent' : #903CE5,
  'sell': #FD5D7C,
  'daily_rent': #00B972
);

/************************
********* COLORS ********
*************************/

/// background-color: color(primary, blue)
/// @param {Color} $color - Predefined color name e.g grey
/// @param {Tone} $tone - Availble tone for given color e.g 'dark' | 'light'
@function get-color($color, $tone: 'base') {
  @return map-get(map-get($colors, $color), $tone);
}

// .fill-primary-blue {background-color: blue}
@each $color-key, $color-value in $colors {
  @each $tone-key, $tone-value in $color-value {
      .fill-#{$color-key}-#{$tone-key} {
        background-color: $tone-value;
      }

  }
}

//Dimensions
$border-radius-main: 2px;

// Layout
$header-top-height: 2.5rem;
$header-bottom-height: 4.6725rem;
$filters-height: 5.875rem;
$filters-mobile-height: 5.3rem;
$header-mobile-height: 3.7rem;
$dropdown-action-bar-height: 4rem;

$search-page-top: $header-top-height + $header-bottom-height + $filters-height;
$dropdown-scrollable-area-height-offset: $filters-mobile-height + $header-mobile-height + $dropdown-action-bar-height;

// grid
$grid-cols: 12;
$grid-gap: 0.675rem; // 10px

// Font sizes: Standard properties!
$font-size: (
    'xx-small': 0.5625rem, //9px
    'x-small': 0.625rem,   //10px,
    'small': 0.75rem,      //12px,
    'medium': 1rem,        //16px,
    'large': 1.125rem,     //18px,
    'x-large': 1.5rem,     //24px,
    'xx-large': 2rem,      //32px
);

@function get-font ($key) {
    @return map-get($font-size, $key);
}

// Font weight: Standard properties!
$font-weight: (
    'normal': 400,
    'medium': 500,
    'semi-bold': 600,
    'bold': 700,
    'bolder': 900,
);

@function get-font-weight ($key) {
    @return map-get($font-weight, $key);
}
