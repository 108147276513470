.draw-search-action-bar {
    opacity: 1;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);
    flex-direction: row;
    align-items: center;
    pointer-events: auto;
    position: absolute;
    z-index: 999999;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.25rem;


    div:last-child {

        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.25rem;
        span:not(.span-divider){
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            cursor: pointer;
        }
        span.span-divider{
            height: 1.25rem;
            width: 2px;
            background-color: white;
            margin: 0 1rem;
            display: block;
        }
    }

}

.h-100 {
    height: 100%;
}

.svg-draw {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;

}

.position-relative-w-100 {
    position: absolute;
    width: 100%;
    height: 100%
}
