@import '~semantic-ui-css/semantic.min.css';
@import '~react-spring-bottom-sheet/dist/style.css';
// Fonts
@import "fonts/googleFont";
@import "icons";

// Variables
@import 'variables';
@import "mixins";
@import "layout/main";
@import "elements/main";
@import "components/main";
@import "forms/main";
@import "pages/main";
@import "colors";


