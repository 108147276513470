.flag{
    width: 1.3125rem;
    height: 1.3125rem;
    background-repeat:  no-repeat;
    //background-size: contain;
    display: inline-block;
    &.flag-am{
        background-image: url(../../images/icons/countries/armenia.svg);
    }
    &.flag-ru{
        background-image: url(../../images/icons/countries/russia.svg);
    }
    &.flag-uk{
        background-image: url(../../images/icons/countries/united-kingdom.svg);
    }
}
.cm-dropdown{
    .flag{
        margin-right: 0.675rem;
    }
}
