html{
    font-size: 16px;
    font-family: 'Noto Sans Armenian', sans-serif;
}

@media only screen and (min-width:$desktop-breakpoint){
    html{
        @include fluid-type($mobile-breakpoint, 1920px, 9.2px, 19.2px);

    }
}
@media only screen and (max-width:$mobile-breakpoint ){
    html{
        @include fluid-type(320px, 375px, 13px, 16px);
    }
}
.container{
    margin: 0 auto;
    @media only screen and (min-width: $desktop-breakpoint){
        padding: 0 3rem;
    }
    @media only screen and (max-width: $mobile-breakpoint){
        padding: 0 1.4rem;
    }

}
h1,h2,h3,h4{
    font-weight: 600;
}
h1{
    font-size: 3rem;
}
h2{
    font-size: 2.0625rem;
}
h3{
    font-size: 1.5rem;
}
h4{
    font-size: 1.25rem;
}
// flex blocks
.flex-center{
    display: flex;
    align-items: center;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-middle{
    display: flex;
    align-items: center;
    justify-content: center;
}
.row{
    display: flex;
    width: 100%;
}
.main-content{
    min-height: calc(100vh - 42.5rem); //force footer to stick in the bottom for empty content
    .container{
        display: flex;
        height: 100%;
        @media only screen and (max-width: $mobile-breakpoint){
            flex-direction: column;
        }
    }
    &.with-sidebar{
        .content{
            width: 52.5%;
            @media only screen and (max-width: $mobile-breakpoint){
                width: 100%;
            }
        }
    }

}
.text-uppercase{
    text-transform: uppercase !important;
}
.text-right{
  text-align: right;
}
.content{
    .page-title{
        font-size: 2.0625rem;
        line-height: 2.5rem;
        padding: 1.3125rem 0 1.1875rem 0;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}
.justify-content-between {
    justify-content: space-between;
}
.form-input{
    input[type=email],
    input[type=number],
    input[type=datetime-local],
    .flex-wrap,
    select {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        margin: 1px 3px;
        resize: none;
    }
    label:not(.checkbox-label) {
        padding: 12px 12px 12px 0;
        display: inline-block;
    }

}
.logo{
    height: 1.5625rem;
}

ul {
    &.check-list {
        li {
            margin: 0.40625rem 0;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 20px;

            &:before{
                top: 3px;
                left: 0;
                position: absolute;
                width: 0.675rem;
                height: 0.675rem;
                content: "";
                display: block;
                background: url(./../../images/icons/check.svg) no-repeat;
                background-size: contain;
                margin-right: 10px;
                flex: 1 1 auto;
            }
        }
    }
}

.row::after {
    content: "";
    clear: both;
    }
[class*="col-"] {
    float: left;
    padding: 15px;
}
* {
    box-sizing: border-box;
}
.flex-column {
    flex-direction: column;
}

// grid layout
@include columns('col', $grid-gap, $grid-cols);

.text-blue {
    color: get-color('primary', 'blue');
}
.mobile-hidden{
    @media only screen and (max-width:$mobile-breakpoint){
        display: none !important;
    }
}
.responsive-img{
    display: inline-block;
    max-width: 100%;
}
@media only screen and (max-width:$mobile-breakpoint){
    .col-4,.col-6{
        width: 100%;
    }
}
//TODO: Move to mixins
.w-25 {
    width: 25%;
}

/*
 * START DISPLAYS
 */
.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}
/*
 * END DISPLAYS
 */

.p-0 {
    padding: 0;
}
.container-1024 {
    max-width: 67.75rem;
    width: 100%;
    margin: auto;
    padding: 0 1.875rem;
}
.container-860 {
    max-width: 53.75rem;
    width: 100%;
}

.invalid-input {
    border-color: $color-red-main !important;
}

.invalid-input-error-message {
    font-size: 0.75rem;
    text-align: left;
    color: $color-pink-main;
    display: block;
    margin-top: 0.125rem;
    line-height: 0.9375rem;
}
.no-scroll{
    @media only screen and (max-width: $tablet-portrait-breakpoint) {
        overflow: hidden;
    }
}

body.bl_router_modal_opened {
    @media only screen and (max-width: $desktop-breakpoint) {
        overflow: hidden !important;
    }
}
.mobile-hidden{
    @media only screen and (max-width:$mobile-breakpoint) {
        display: none!important;
    }
}

.glightbox-open {
    height: auto;
}
