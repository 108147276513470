.support {
    .title-h2 {
        font-size: 2.0625rem;
        line-height: 2.5rem;
        margin-top: 4.375rem !important;
        margin-bottom: 2.5rem;
    }

    .toggle {
        display: none;
    }

    .option {
        position: relative;
        margin-bottom: 1em;
        border: 1px solid $color-grey-light;
        border-radius: 0.375rem;
        padding: 2px;
    }

    .title,
    .content {
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.2s;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.125rem 2rem;
        color: $color-green;
        font-size: 1.25rem;
        font-weight: bold;
        cursor: pointer;
    }

    .title {
        i {
            transition: all 0.3s;
            color: $color-green;
         }
    }

    .content {
        max-height: 0;
        overflow: hidden;
        background-color: #fff;

        p {
            margin: 0;
            padding: 1.125rem 2rem;
            font-size:0.875rem;
            line-height: 1.5;
        }
    }

    .toggle:checked {
        & + .title,
        & + .title + .content {
            background-color: #FCFCFC;
        }

        & + .title + .content {
            max-height: 31.25rem;
            max-width: 56.25rem;
        }

        & + .title {
            .icon-chevron-down {
                transform: rotate(180deg) !important;
            }
        }
    }

    .about-section {
        div {
            margin-top: 2.375rem;
            width: 100%;
            p {
                text-align: justify;
                white-space: pre-line;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                margin-top: 10px;
            }
        }
    }
}
/**Global style**/
.banner-wrap {
    color: #FFFFFF;
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    background-position: 100% 100%;
    background-size: cover;

    span {
        font-size: 3rem;
        line-height: 5.375rem;
    }

    h1 {
        line-height: 5.375rem;
    }
}
